import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 720 714">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">
          <path
            fill="#FEFEFE"
            opacity="1.000000"
            stroke="none"
            d="
M465.000000,715.000000 
	C310.000000,715.000000 155.500000,715.000000 1.000000,715.000000 
	C1.000000,477.000000 1.000000,239.000000 1.000000,1.000000 
	C241.000000,1.000000 481.000000,1.000000 721.000000,1.000000 
	C721.000000,239.000000 721.000000,477.000000 721.000000,715.000000 
	C635.833313,715.000000 550.666687,715.000000 465.000000,715.000000 
M402.914520,192.362671 
	C402.290253,191.574432 401.666016,190.786194 400.880432,189.748444 
	C400.880432,189.748444 400.605194,189.636429 400.681366,188.842590 
	C396.362946,180.109848 392.044495,171.377106 387.729858,162.346527 
	C387.729858,162.346527 387.480286,162.183929 387.553070,161.480896 
	C386.118286,158.885345 384.683502,156.289795 383.142059,153.081924 
	C382.892914,152.758804 382.643768,152.435669 382.561951,151.553528 
	C382.044586,151.054031 381.527222,150.554550 380.787415,149.556091 
	C380.474548,149.386078 380.161652,149.216064 379.611206,148.441895 
	C376.624451,146.349060 373.599670,144.494431 369.194824,146.941727 
	C367.150665,148.692001 365.106537,150.442261 362.477692,152.308914 
	C362.214478,153.207275 361.951263,154.105637 361.237732,155.571899 
	C360.421295,157.289627 359.604828,159.007355 358.173950,160.998901 
	C354.978607,167.217194 351.715759,173.401855 348.600800,179.660156 
	C339.771667,197.398941 331.097748,215.216202 322.088104,232.862335 
	C320.988312,235.016357 318.283783,237.675064 316.203827,237.781982 
	C305.586609,238.327789 294.928436,238.013351 284.288391,238.208038 
	C279.543457,238.294846 274.807495,238.870010 269.344421,239.106201 
	C261.640808,241.942596 259.078491,248.331085 262.819366,255.868912 
	C269.625946,269.584045 276.326111,283.360291 283.537781,296.861572 
	C286.091705,301.642853 286.245758,305.421722 283.683990,310.253418 
	C276.589935,323.633331 269.967163,337.265625 263.289520,350.862701 
	C259.961304,357.639587 261.832153,363.254364 268.847992,366.310242 
	C281.405945,366.272797 293.963898,366.235352 306.926147,366.586029 
	C308.102661,366.453613 309.279205,366.321228 311.307953,366.414886 
	C316.374908,366.343658 321.441833,366.272430 326.996490,366.631134 
	C328.811951,366.481049 330.627411,366.330963 333.255981,366.404114 
	C335.360565,366.404114 337.465149,366.404114 340.493896,366.404114 
	C339.262695,363.543365 338.554810,361.559296 337.588623,359.710144 
	C332.349030,349.681732 327.029327,339.695190 321.783905,329.669800 
	C321.101227,328.365082 319.833954,326.439117 320.294434,325.620819 
	C322.513153,321.678284 319.715973,319.007538 318.350281,316.009247 
	C316.902740,312.831299 314.948090,309.863129 313.730927,306.610260 
	C313.114410,304.962494 313.072144,302.655212 313.739899,301.049377 
	C316.478638,294.463348 319.406677,287.940491 322.598999,281.561554 
	C332.280426,262.216003 342.155609,242.967575 351.878540,223.642700 
	C357.322449,212.822571 362.614349,201.925964 368.529144,190.617096 
	C369.355621,188.632141 370.182098,186.647186 371.762604,184.509262 
	C372.518890,184.595947 373.275177,184.682617 374.211151,185.445297 
	C374.375336,185.610809 374.539520,185.776337 374.519653,186.470947 
	C374.965363,187.014008 375.411102,187.557068 375.965637,188.829910 
	C377.289612,191.697540 378.613617,194.565155 380.074615,197.357819 
	C380.074615,197.357819 379.946320,197.195679 380.380066,197.891602 
	C380.825104,198.573151 381.270111,199.254715 381.534119,200.478012 
	C381.972260,201.043716 382.410370,201.609436 382.958954,202.850861 
	C383.244904,203.199966 383.530823,203.549088 383.601288,204.532898 
	C384.672791,206.452835 385.744263,208.372757 387.032501,210.910767 
	C387.485657,211.614365 387.938782,212.317963 388.492554,213.670120 
	C389.310425,215.141754 390.128296,216.613373 390.971802,218.728821 
	C391.580383,219.520767 392.188995,220.312729 392.823090,221.772964 
	C393.243378,222.520386 393.663666,223.267822 394.047394,224.694473 
	C395.034454,226.057495 396.021515,227.420517 396.819824,229.533493 
	C397.333466,230.678940 397.847137,231.824402 398.224640,233.657578 
	C399.063080,235.300629 399.901550,236.943695 400.618500,239.244415 
	C400.905182,239.825150 401.191895,240.405884 401.349457,241.675034 
	C404.542145,247.489700 407.734833,253.304352 410.983398,259.642700 
	C411.114197,259.759796 411.244995,259.876923 411.294952,260.666901 
	C412.524567,262.446045 413.754181,264.225189 415.019745,266.569489 
	C415.145203,266.710541 415.270691,266.851593 415.260620,267.708496 
	C420.293152,278.018127 425.490143,288.253265 430.219147,298.700317 
	C431.162201,300.783661 431.269470,304.268829 430.133942,306.095276 
	C424.769104,314.724182 423.112793,324.515625 420.016022,334.391907 
	C419.997894,334.584167 419.979767,334.776428 419.322296,335.148132 
	C414.245026,344.619781 409.137939,354.075867 404.164642,363.601807 
	C403.867218,364.171509 404.639801,365.299835 405.246979,366.843018 
	C406.852478,367.068237 408.457581,367.486084 410.063538,367.489441 
	C430.541046,367.531982 451.039917,366.970245 471.486359,367.767670 
	C480.303925,368.111542 486.849884,359.554291 482.101013,350.178040 
	C475.053284,336.262848 468.409210,322.135193 461.031342,308.399841 
	C458.557953,303.795074 458.687012,300.330841 461.059937,295.941589 
	C463.843903,290.791992 465.822510,285.207001 468.587860,279.251373 
	C468.703644,278.795227 468.819427,278.339081 469.550507,277.805145 
	C469.726807,277.150818 469.903076,276.496460 470.555725,275.285614 
	C470.685822,274.820496 470.815918,274.355377 471.597870,273.767792 
	C475.185822,266.665863 479.022980,259.675201 482.264191,252.418396 
	C484.078552,248.356201 482.157990,244.489227 479.293396,241.463730 
	C475.281586,237.226562 463.363190,235.945908 456.985229,238.727631 
	C455.403076,238.772919 453.820892,238.818222 451.807220,238.236359 
	C445.560913,238.110657 439.311188,237.806671 433.069641,237.927292 
	C429.428772,237.997665 426.097107,238.141953 424.178558,234.240952 
	C420.231232,226.214935 416.302612,218.177383 412.190857,210.235413 
	C409.324524,204.699036 406.166382,199.313751 403.032410,193.420471 
	C403.032410,193.420471 402.787964,193.039276 402.914520,192.362671 
M179.828003,493.559418 
	C180.434677,493.851227 181.041351,494.143036 181.648056,494.434845 
	C181.806488,493.950562 181.964920,493.466248 182.331787,492.081543 
	C181.604599,487.709503 184.314102,482.731812 179.798523,478.396698 
	C177.200394,478.409943 174.598160,478.346985 172.006775,478.485901 
	C171.289062,478.524353 170.154388,479.632904 169.969482,479.456329 
	C166.241272,475.895844 162.165985,477.729309 157.660278,477.983551 
	C156.960083,478.489349 155.666901,478.978943 155.652573,479.503479 
	C155.526718,484.108063 155.580780,488.720551 155.739563,493.325256 
	C155.755020,493.773987 156.833145,494.186066 158.224976,495.207214 
	C158.616318,490.430878 158.942596,486.448853 159.929337,481.943359 
	C164.360825,479.239349 167.094833,480.514099 167.503220,485.493988 
	C167.748047,488.479462 167.951614,491.468353 168.173737,494.455688 
	C168.974091,494.414368 169.774429,494.373047 170.574783,494.331726 
	C170.574783,491.651825 170.465332,488.966492 170.598343,486.293182 
	C170.848862,481.258453 172.950745,479.788940 178.457718,481.555573 
	C178.967545,485.371826 179.477371,489.188110 179.828003,493.559418 
M210.474625,478.528198 
	C206.628143,476.327209 203.068909,476.778290 200.366425,480.258759 
	C197.478577,483.977966 197.857712,489.538910 201.093872,492.562073 
	C204.124969,495.393707 207.504486,495.549377 211.651276,493.155334 
	C213.273621,494.120941 215.448013,496.203308 215.755386,492.378265 
	C216.132446,487.685913 215.845291,482.940155 215.845291,477.567017 
	C213.666138,478.228302 212.330795,478.633545 210.474625,478.528198 
M622.451111,478.597504 
	C620.341736,477.027283 618.921143,477.477020 618.835999,480.157166 
	C618.703857,484.315399 618.745544,488.486420 618.942627,492.641144 
	C618.978333,493.393127 620.113708,494.092987 621.786011,496.021698 
	C622.006104,492.370361 621.975586,490.275299 622.295227,488.235138 
	C622.689026,485.722229 623.374573,483.255035 624.831177,480.470398 
	C628.991516,479.497009 630.784058,481.448792 630.876038,485.416870 
	C630.926270,487.580566 630.720459,489.764984 630.962585,491.902405 
	C631.064392,492.801453 632.025085,493.603271 632.595276,494.449310 
	C633.158630,493.569977 634.091003,492.737152 634.208923,491.801666 
	C634.456848,489.835114 634.323303,487.813324 634.264832,485.816559 
	C634.054382,478.630768 630.033020,476.159393 622.451111,478.597504 
M495.639069,493.800507 
	C496.758453,492.229340 497.877808,490.658142 499.494171,488.739807 
	C499.615936,487.752014 499.737701,486.764252 499.792419,485.076721 
	C496.968781,476.296356 490.344238,474.582153 483.117859,481.101929 
	C481.921051,483.714417 479.002228,486.277435 483.205658,489.436035 
	C483.311462,489.756622 483.417297,490.077240 483.280853,491.132721 
	C483.927917,491.709564 484.574951,492.286407 485.726746,493.052490 
	C486.142456,493.385498 486.558167,493.718536 487.061432,494.697876 
	C488.049438,494.748199 489.037415,494.798523 490.755035,494.720642 
	C491.492798,494.756378 492.230591,494.792114 493.223053,495.323456 
	C493.842621,494.975647 494.462189,494.627808 495.639069,493.800507 
M460.697601,480.254547 
	C461.716888,482.621918 462.918304,484.931000 463.700409,487.374298 
	C464.380310,489.498322 464.586151,491.774078 465.485107,494.291229 
	C465.658173,494.209930 465.831207,494.128632 466.752716,493.879700 
	C466.926544,490.119843 467.630981,486.287476 467.129791,482.619873 
	C466.594208,478.700623 463.444427,476.828217 459.503082,477.080292 
	C456.974945,477.242004 454.481049,477.939606 451.635376,478.458344 
	C451.615143,478.500336 451.237579,478.928558 451.225403,479.366943 
	C451.138000,482.513092 450.872772,485.686188 451.180176,488.799744 
	C451.373108,490.754028 452.461395,492.619965 453.148010,494.525513 
	C453.753387,492.677429 454.423676,490.847595 454.948975,488.977020 
	C455.727386,486.204956 456.402985,483.404053 457.843475,480.449707 
	C458.560913,480.282990 459.278320,480.116302 460.697601,480.254547 
M293.887207,490.843567 
	C293.924805,489.229034 293.962402,487.614532 293.973785,485.196320 
	C292.556061,478.860565 289.363953,476.314728 284.381714,477.388214 
	C280.273376,478.273376 278.004669,480.762695 277.512604,484.870392 
	C277.024323,488.946533 278.497711,492.378357 282.254395,493.917419 
	C286.334747,495.589172 290.437195,495.302948 293.887207,490.843567 
M482.340393,423.344666 
	C483.606018,417.942017 484.871643,412.539337 486.149323,407.085327 
	C476.981781,405.891174 476.978668,405.890533 475.300873,413.859283 
	C474.376801,418.248016 473.529236,422.654022 472.532471,427.026062 
	C471.136078,433.150970 469.632782,439.251465 468.175232,445.362396 
	C467.736786,445.337891 467.298370,445.313354 466.859955,445.288818 
	C466.322235,443.603912 465.722107,441.936066 465.257141,440.231323 
	C462.543640,430.282593 459.668579,420.371582 457.265472,410.347748 
	C456.320465,406.405945 453.876099,406.622192 450.947876,406.528320 
	C447.933899,406.431702 446.188202,406.967255 445.339325,410.478149 
	C442.839996,420.815155 439.839020,431.031647 436.980377,441.280212 
	C436.553253,442.811523 435.835693,444.261841 435.252472,445.749634 
	C431.802307,434.729004 429.537262,424.024384 427.194611,413.336761 
	C425.533997,405.760742 424.748779,405.345520 416.697174,407.346619 
	C416.662628,407.805237 416.497772,408.319794 416.608521,408.765930 
	C420.610992,424.889221 424.719299,440.986877 428.604401,457.138245 
	C429.515228,460.924744 432.083862,460.486481 434.735382,460.428406 
	C437.452118,460.368958 440.299713,461.183563 441.200867,456.984283 
	C442.629150,450.328705 444.612274,443.789856 446.431610,437.221313 
	C447.804352,432.265289 449.288177,427.340088 451.105225,421.083527 
	C452.228790,423.654297 452.824310,424.661987 453.121094,425.751099 
	C455.918701,436.016449 458.905975,446.239868 461.305725,456.598846 
	C462.237762,460.622253 464.597900,460.519165 467.523163,460.436615 
	C470.451843,460.353943 473.289764,461.070831 474.244476,456.591766 
	C476.564392,445.707825 479.487213,434.952393 482.340393,423.344666 
M295.782227,447.483978 
	C295.782227,439.619385 295.782227,431.754791 295.782227,423.890228 
	C296.229126,423.794678 296.676025,423.699158 297.122925,423.603607 
	C298.079865,425.064240 299.036407,426.525116 299.993744,427.985474 
	C306.471252,437.866425 313.049713,447.683411 319.374298,457.661285 
	C321.725403,461.370453 325.151947,460.721954 328.219299,460.076782 
	C329.165405,459.877838 329.971710,456.636658 329.989838,454.780151 
	C330.132935,440.121094 329.936096,425.458008 330.159210,410.800995 
	C330.218689,406.891724 328.457977,406.593048 325.353363,406.522217 
	C322.140442,406.448883 320.945648,407.334991 321.031036,410.728577 
	C321.253021,419.552673 321.154938,428.386230 321.082855,437.215149 
	C321.069702,438.821899 320.574860,440.424713 320.077393,443.354401 
	C318.223175,440.976898 317.246704,439.893616 316.458557,438.687286 
	C310.540924,429.630310 304.654999,420.552643 298.770386,411.474121 
	C295.276093,406.083313 293.802979,405.567047 286.954285,407.627991 
	C286.954285,422.825958 286.956635,438.129089 286.953308,453.432190 
	C286.951599,461.259125 287.165100,461.427856 295.782166,459.686279 
	C295.782166,456.042786 295.782166,452.259247 295.782227,447.483978 
M534.949707,455.404449 
	C537.362244,452.504303 540.284363,449.888763 542.092773,446.651154 
	C547.716797,436.582611 546.263794,423.649506 539.001709,414.969788 
	C532.498108,407.196594 520.858276,404.004852 510.914825,407.172363 
	C499.396149,410.841675 493.522583,419.302612 492.455444,430.720642 
	C491.493134,441.017090 494.890564,450.192444 504.024414,456.113647 
	C514.084961,462.635620 524.309448,462.462280 534.949707,455.404449 
M563.299866,448.447479 
	C563.299866,445.503204 563.299866,442.558929 563.299866,439.614655 
	C563.769165,439.196991 564.238403,438.779327 564.707703,438.361694 
	C566.795532,439.617096 569.482971,440.417297 570.850891,442.219269 
	C574.054810,446.439880 576.620300,451.144409 579.455566,455.645874 
	C582.886475,461.092987 584.759705,461.695099 591.572510,459.304504 
	C587.343506,452.546753 583.158691,445.859680 578.819336,438.925568 
	C579.882568,438.406494 580.428711,438.097534 581.005615,437.864502 
	C589.565796,434.407715 592.224182,429.806885 591.393311,419.862640 
	C590.823059,413.038208 586.628357,408.577454 578.104553,407.329529 
	C571.746216,406.398621 565.201538,406.812988 558.758545,406.361633 
	C554.903137,406.091553 553.992371,407.693481 554.036621,411.291931 
	C554.208679,425.260956 554.110840,439.233398 554.099487,453.204590 
	C554.093018,461.144531 554.568848,461.516052 563.299438,459.638947 
	C563.299438,456.334930 563.299438,452.885193 563.299866,448.447479 
M134.816391,440.423462 
	C137.935181,437.807068 140.384933,438.634094 142.398102,441.870544 
	C145.295059,446.527863 148.162552,451.204590 151.133102,455.814758 
	C154.590042,461.179749 156.282333,461.664520 163.283600,459.386810 
	C158.948685,452.554260 154.651184,445.780640 150.229218,438.810883 
	C152.093246,438.066284 153.170456,437.675323 154.215591,437.212036 
	C160.467560,434.440735 163.908356,428.739594 163.373886,422.072754 
	C162.759857,414.413544 159.268799,408.974731 152.363159,407.881836 
	C143.800171,406.526642 135.024368,406.516022 126.167114,405.912781 
	C126.167114,424.717499 126.167114,442.312439 126.167114,460.095947 
	C129.012894,460.095947 131.593887,460.095947 134.024963,460.095947 
	C134.325653,459.496490 134.592163,459.210785 134.595673,458.921906 
	C134.666733,453.095795 134.707596,447.269318 134.816391,440.423462 
M622.163818,440.190094 
	C616.481384,432.625641 616.506226,432.647858 623.002747,425.375885 
	C628.284851,419.463287 633.497986,413.489044 638.859009,407.409088 
	C631.566162,405.198792 629.308777,405.923462 625.082336,410.906097 
	C620.098938,416.781219 614.929077,422.498138 609.840088,428.283661 
	C609.282410,428.124298 608.724670,427.964966 608.166992,427.805603 
	C608.166992,422.280060 607.957275,416.743744 608.240479,411.232758 
	C608.430603,407.532501 607.283936,406.244781 603.486755,406.306000 
	C599.919495,406.363556 598.521179,407.204987 598.576416,411.095764 
	C598.791748,426.253998 598.652832,441.417114 598.677612,456.578400 
	C598.679443,457.691223 598.934814,458.803680 599.092834,460.082489 
	C602.100220,460.082489 604.836609,460.082489 608.445190,460.082489 
	C608.445190,452.668854 608.445190,445.539154 608.445190,438.409454 
	C614.836365,443.927856 620.455078,449.394623 624.845520,455.714661 
	C628.608582,461.131561 633.094299,460.720642 639.470520,459.906342 
	C633.451599,453.065521 628.035461,446.909790 622.163818,440.190094 
M227.781036,421.900818 
	C229.210846,424.145844 230.390961,426.610657 232.128555,428.584778 
	C234.788956,431.607452 234.460052,434.058716 232.307190,437.324310 
	C227.580215,444.494568 223.333588,451.981476 218.834061,459.433228 
	C226.486694,461.712799 228.059235,461.057770 231.584503,454.873901 
	C234.096542,450.467407 236.784515,446.161194 239.938461,440.902405 
	C243.480743,446.308685 246.386307,450.873993 249.425232,455.348724 
	C253.363068,461.147064 255.089554,461.671814 262.610352,459.374329 
	C258.126221,452.448639 253.903732,445.519684 249.248123,438.895142 
	C246.612793,435.145294 246.261810,432.129211 248.992722,428.162292 
	C253.577499,421.502411 257.565887,414.431946 261.867371,407.433014 
	C254.081284,405.240265 252.880020,405.614166 249.266403,411.495270 
	C246.627625,415.789856 244.112839,420.160583 240.965179,425.472107 
	C237.446075,420.150360 234.579224,415.945007 231.848190,411.653229 
	C228.152985,405.846313 225.698944,404.993652 218.508835,407.417999 
	C221.502106,412.066711 224.464813,416.667999 227.781036,421.900818 
M185.636963,448.321564 
	C188.800964,448.320160 192.016510,448.680389 195.117676,448.238251 
	C200.705811,447.441620 203.537598,449.467926 205.054367,455.015259 
	C206.765945,461.275085 208.809494,461.813141 215.316528,459.378265 
	C215.352188,459.054565 215.506714,458.687408 215.406525,458.413391 
	C209.473877,442.183899 203.536331,425.956146 197.557281,409.743713 
	C196.040695,405.631409 192.387573,407.450317 189.769440,407.543579 
	C188.209122,407.599091 185.960938,409.389465 185.327682,410.934937 
	C181.803238,419.535980 178.655212,428.292145 175.423798,437.012054 
	C172.622269,444.571869 169.881180,452.154022 167.061310,459.868774 
	C175.938889,461.066895 175.958511,461.073975 178.562714,453.863831 
	C179.630219,450.908264 180.162033,447.440826 185.636963,448.321564 
M367.049652,435.719818 
	C367.159088,433.824219 367.268524,431.928589 367.412872,429.428406 
	C360.710754,429.428406 354.631378,429.428406 347.984741,429.428406 
	C347.984741,425.581635 348.208038,422.277405 347.926971,419.016632 
	C347.592560,415.137146 348.896301,413.636139 352.928741,413.854919 
	C358.483490,414.156219 364.066803,413.930450 369.689514,413.930450 
	C369.689514,411.044708 369.689514,408.971405 369.689514,406.848114 
	C359.346954,406.848114 349.312683,406.848114 338.919617,406.848114 
	C338.919617,421.055511 338.919159,434.834930 338.919800,448.614319 
	C338.920319,460.448486 338.920837,460.442383 350.989471,460.414917 
	C357.218933,460.400726 363.448456,460.412476 369.612854,460.412476 
	C369.612854,457.317139 369.612854,455.102875 369.612854,452.577332 
	C366.758362,452.577332 364.277802,452.573486 361.797241,452.577972 
	C347.099670,452.604706 347.097534,452.605194 348.190155,437.932220 
	C348.211823,437.641022 348.462952,437.366882 349.042114,436.232910 
	C354.548798,436.232910 360.489960,436.232910 367.049652,435.719818 
M398.129120,440.499878 
	C398.129120,431.876923 398.129120,423.253967 398.129120,413.853394 
	C402.223053,413.853394 405.836945,413.780731 409.446411,413.876373 
	C412.337982,413.952942 413.031952,412.117767 412.435577,410.006622 
	C412.067078,408.702118 410.531403,406.793518 409.472595,406.762451 
	C398.994385,406.455200 388.505890,406.522247 378.021484,406.390045 
	C375.516785,406.358459 374.384979,407.188995 374.457123,409.850708 
	C374.523407,412.296448 374.757385,414.141724 377.892029,414.036224 
	C381.519012,413.914154 385.153198,414.007782 389.542297,414.007782 
	C389.542297,429.679779 389.542297,444.870972 389.542297,460.125793 
	C392.736389,460.125793 395.164551,460.125793 398.128906,460.125793 
	C398.128906,453.737061 398.128906,447.618225 398.129120,440.499878 
M371.100800,487.349396 
	C370.155823,487.931213 369.109100,488.396210 368.283356,489.115051 
	C364.615173,492.308228 360.889374,492.584076 358.149414,489.768311 
	C354.964905,486.495728 354.842102,481.363647 357.868744,478.040466 
	C360.518890,475.130615 364.371307,475.195435 368.101410,478.087036 
	C369.212860,478.948608 370.541229,479.530365 372.535461,480.682404 
	C370.553070,474.104706 366.545776,471.858307 360.341461,472.870789 
	C355.293060,473.694702 352.419373,478.000732 352.595856,484.477325 
	C352.749756,490.127045 355.972168,494.075653 360.873627,494.620605 
	C367.442627,495.351013 371.698090,492.578552 371.100800,487.349396 
M133.987228,475.712402 
	C135.612244,476.684296 137.237274,477.656189 139.526917,479.025604 
	C139.472656,473.819244 136.779327,473.061340 133.603241,472.825958 
	C130.201859,472.573914 127.201050,473.161560 126.029861,476.899872 
	C124.985222,480.234192 126.871002,482.278290 129.544769,483.731964 
	C130.844254,484.438477 132.450089,484.638367 133.646973,485.460876 
	C134.871841,486.302612 136.173325,487.480896 136.639984,488.796173 
	C136.887650,489.494141 135.499649,491.590332 134.736847,491.651855 
	C133.324188,491.765717 131.754028,490.997803 130.389236,490.316956 
	C129.150940,489.699280 128.098618,488.708801 126.964310,487.882660 
	C126.625069,488.275360 126.285828,488.668030 125.946587,489.060730 
	C126.512581,490.186951 126.848419,492.044098 127.685997,492.307861 
	C130.678299,493.250092 133.919601,494.321655 136.905319,493.970856 
	C138.366821,493.799072 140.877396,490.102814 140.442581,488.880798 
	C139.581543,486.461029 137.064407,484.591888 135.097214,482.630188 
	C134.573196,482.107635 133.363861,482.285858 132.814087,481.772766 
	C131.701645,480.734528 130.177368,479.494781 130.077637,478.238556 
	C130.010330,477.390564 132.088715,476.372223 133.987228,475.712402 
M423.133667,486.438019 
	C423.287842,483.254608 423.842865,480.066925 427.927734,480.340576 
	C431.857697,480.603821 431.539948,484.007019 431.673462,486.801086 
	C431.760559,488.622650 431.490967,490.490051 431.810242,492.259460 
	C431.966644,493.126221 433.100769,493.816589 433.792358,494.586792 
	C434.435272,493.951263 435.641968,493.303864 435.628876,492.682190 
	C435.549377,488.906311 435.644806,485.030762 434.797699,481.394470 
	C433.977966,477.875458 430.865509,476.721069 427.373779,477.172638 
	C425.084412,477.468750 422.824799,477.995209 419.542572,478.606476 
	C419.542572,481.061829 419.295532,484.145782 419.619720,487.168518 
	C419.885254,489.644409 420.831116,492.047333 421.475403,494.482605 
	C422.027039,492.106720 422.578674,489.730804 423.133667,486.438019 
M517.221130,488.992188 
	C516.458740,485.985535 515.916382,482.956177 519.212463,481.056396 
	C522.478699,479.173828 524.363831,481.174927 526.205261,483.639679 
	C526.620667,484.195557 528.128967,483.934845 529.366150,484.076630 
	C527.738831,478.729004 524.451599,476.382416 520.235352,477.111542 
	C515.553711,477.921143 512.288086,482.137604 512.751770,486.773773 
	C513.243774,491.692932 516.035645,494.473999 520.732544,494.723602 
	C525.855042,494.995758 528.189636,493.267548 529.108459,487.642731 
	C527.466431,488.425476 526.042419,488.694305 525.264404,489.550293 
	C522.695129,492.376892 520.251160,492.340363 517.221130,488.992188 
M393.629822,477.571411 
	C388.268951,479.832703 386.648621,482.675629 387.703796,488.512634 
	C388.368225,492.187897 390.674774,494.306915 394.476959,494.657898 
	C400.211334,495.187256 403.888397,492.139221 404.137817,486.640991 
	C404.426208,480.284607 401.116028,477.160614 393.629822,477.571411 
M595.295227,494.624878 
	C601.044617,493.502533 603.739258,490.279419 603.221497,485.144257 
	C602.772034,480.686249 598.719727,476.909698 594.520508,477.035461 
	C590.315735,477.161346 586.465820,481.070740 586.140259,485.545105 
	C585.773132,490.592987 588.833923,493.912659 595.295227,494.624878 
M262.176361,491.587433 
	C258.728149,487.910187 259.418823,481.771545 262.877014,479.416138 
	C261.941406,478.186890 260.818420,476.884918 259.891602,475.455750 
	C258.220673,472.879181 256.836884,472.792542 255.967972,475.892273 
	C254.325241,481.752533 255.777512,487.425842 257.821350,492.830841 
	C258.219025,493.882477 261.242950,493.941040 263.055511,494.457672 
	C263.020996,493.538818 262.986481,492.619995 262.176361,491.587433 
M544.957214,486.269196 
	C545.035095,487.919891 544.823242,489.649963 545.249084,491.205383 
	C546.245605,494.844635 549.601807,495.542175 551.451538,493.800110 
	C550.266785,490.696136 548.537048,488.152313 548.544312,485.613434 
	C548.551270,483.164673 550.351196,480.721008 551.363037,478.276062 
	C550.351196,477.198700 548.826233,475.574951 547.301208,473.951172 
	C545.616577,475.593719 543.932007,477.236237 543.271912,477.879822 
	C544.031433,481.270844 544.494080,483.336639 544.957214,486.269196 
M317.531189,477.585327 
	C314.876129,477.822266 312.221069,478.059174 308.952148,478.350922 
	C308.952148,482.134277 308.792236,486.075043 309.033508,489.991089 
	C309.125702,491.486877 310.136475,492.926117 310.728638,494.391113 
	C311.255981,492.835754 312.136780,491.303558 312.240692,489.720398 
	C312.496124,485.829987 311.412537,481.406250 317.304230,480.623169 
	C317.625824,480.580414 317.741333,478.987305 317.531189,477.585327 
M236.283386,478.287384 
	C234.901306,478.112518 233.519241,477.937653 231.489502,477.680878 
	C231.562607,483.568115 230.121323,489.198792 233.343307,494.586823 
	C234.362442,492.120697 234.614395,489.643311 234.598633,487.167664 
	C234.580154,484.269165 235.134201,481.981842 238.357010,480.972015 
	C239.234329,480.697113 239.758194,479.294098 240.444290,478.408875 
	C239.290527,478.268127 238.136749,478.127380 236.283386,478.287384 
M567.484375,493.683533 
	C568.454956,493.104309 570.227417,492.573730 570.265991,491.937988 
	C570.515259,487.827789 570.460999,483.691956 570.308960,479.571777 
	C570.283203,478.874023 569.303223,478.211456 568.764099,477.532654 
	C568.242432,477.594055 567.720764,477.655457 567.199097,477.716858 
	C567.199097,482.811920 567.199097,487.906982 567.484375,493.683533 
z"
          />
          <path
            fill="#161616"
            opacity="1.000000"
            stroke="none"
            d="
M482.253784,423.744934 
	C479.487213,434.952393 476.564392,445.707825 474.244476,456.591766 
	C473.289764,461.070831 470.451843,460.353943 467.523163,460.436615 
	C464.597900,460.519165 462.237762,460.622253 461.305725,456.598846 
	C458.905975,446.239868 455.918701,436.016449 453.121094,425.751099 
	C452.824310,424.661987 452.228790,423.654297 451.105225,421.083527 
	C449.288177,427.340088 447.804352,432.265289 446.431610,437.221313 
	C444.612274,443.789856 442.629150,450.328705 441.200867,456.984283 
	C440.299713,461.183563 437.452118,460.368958 434.735382,460.428406 
	C432.083862,460.486481 429.515228,460.924744 428.604401,457.138245 
	C424.719299,440.986877 420.610992,424.889221 416.608521,408.765930 
	C416.497772,408.319794 416.662628,407.805237 416.697174,407.346619 
	C424.748779,405.345520 425.533997,405.760742 427.194611,413.336761 
	C429.537262,424.024384 431.802307,434.729004 435.252472,445.749634 
	C435.835693,444.261841 436.553253,442.811523 436.980377,441.280212 
	C439.839020,431.031647 442.839996,420.815155 445.339325,410.478149 
	C446.188202,406.967255 447.933899,406.431702 450.947876,406.528320 
	C453.876099,406.622192 456.320465,406.405945 457.265472,410.347748 
	C459.668579,420.371582 462.543640,430.282593 465.257141,440.231323 
	C465.722107,441.936066 466.322235,443.603912 466.859955,445.288818 
	C467.298370,445.313354 467.736786,445.337891 468.175232,445.362396 
	C469.632782,439.251465 471.136078,433.150970 472.532471,427.026062 
	C473.529236,422.654022 474.376801,418.248016 475.300873,413.859283 
	C476.978668,405.890533 476.981781,405.891174 486.149323,407.085327 
	C484.871643,412.539337 483.606018,417.942017 482.253784,423.744934 
z"
          />
          <path
            fill="#161616"
            opacity="1.000000"
            stroke="none"
            d="
M295.782196,447.979858 
	C295.782166,452.259247 295.782166,456.042786 295.782166,459.686279 
	C287.165100,461.427856 286.951599,461.259125 286.953308,453.432190 
	C286.956635,438.129089 286.954285,422.825958 286.954285,407.627991 
	C293.802979,405.567047 295.276093,406.083313 298.770386,411.474121 
	C304.654999,420.552643 310.540924,429.630310 316.458557,438.687286 
	C317.246704,439.893616 318.223175,440.976898 320.077393,443.354401 
	C320.574860,440.424713 321.069702,438.821899 321.082855,437.215149 
	C321.154938,428.386230 321.253021,419.552673 321.031036,410.728577 
	C320.945648,407.334991 322.140442,406.448883 325.353363,406.522217 
	C328.457977,406.593048 330.218689,406.891724 330.159210,410.800995 
	C329.936096,425.458008 330.132935,440.121094 329.989838,454.780151 
	C329.971710,456.636658 329.165405,459.877838 328.219299,460.076782 
	C325.151947,460.721954 321.725403,461.370453 319.374298,457.661285 
	C313.049713,447.683411 306.471252,437.866425 299.993744,427.985474 
	C299.036407,426.525116 298.079865,425.064240 297.122925,423.603607 
	C296.676025,423.699158 296.229126,423.794678 295.782227,423.890228 
	C295.782227,431.754791 295.782227,439.619385 295.782196,447.979858 
z"
          />
          <path
            fill="#41DF4C"
            opacity="1.000000"
            stroke="none"
            d="
M420.445221,334.002777 
	C423.112793,324.515625 424.769104,314.724182 430.133942,306.095276 
	C431.269470,304.268829 431.162201,300.783661 430.219147,298.700317 
	C425.490143,288.253265 420.293152,278.018127 415.639954,267.421448 
	C418.407471,270.389709 420.795654,273.645050 423.325623,277.093658 
	C426.226257,275.157349 426.462830,273.375671 424.377197,270.592651 
	C423.585052,269.535583 424.464600,267.225739 424.582214,265.486908 
	C425.895538,266.340576 427.744049,266.901215 428.406525,268.111237 
	C429.640411,270.364868 430.026642,273.066650 431.084930,275.439545 
	C431.572052,276.531769 432.812592,277.287964 433.711517,278.196564 
	C434.167664,277.807922 434.623779,277.419312 435.079926,277.030701 
	C434.540771,275.343353 433.975128,273.663849 433.470825,271.966125 
	C433.060425,270.584473 432.222626,269.091766 432.451904,267.804626 
	C433.041779,264.492645 434.118835,261.267456 435.405975,257.954285 
	C438.876678,257.602722 441.942596,257.304413 445.008362,257.337830 
	C445.010376,258.111816 445.012512,258.554077 444.585815,259.004089 
	C442.250092,259.249786 440.225555,259.160706 438.461548,259.795593 
	C435.060822,261.019531 434.784576,262.945007 438.108795,265.329651 
	C439.872955,268.738068 441.442566,271.858276 443.078735,275.284058 
	C443.764465,276.398163 444.383636,277.206696 444.940063,278.373474 
	C442.246704,284.125092 438.330505,289.092072 443.048065,295.658081 
	C444.835632,298.146057 444.274536,302.227234 445.183655,305.470184 
	C445.921997,308.104004 447.257599,310.570435 448.328339,313.111084 
	C449.081116,313.041138 449.833923,312.971191 450.586700,312.901245 
	C451.079529,310.419800 451.904877,307.948608 451.981781,305.454346 
	C452.079712,302.275818 455.986084,295.951691 458.936401,295.337830 
	C455.691956,300.392090 456.678436,304.818085 459.161957,309.592010 
	C461.860962,314.780182 463.833893,320.346039 465.909821,326.059937 
	C464.093811,326.949493 462.491394,327.526337 460.546936,328.226318 
	C460.546936,326.113525 460.546936,324.754608 460.546936,323.476776 
	C455.980347,325.786957 451.627716,327.988922 447.051727,330.023560 
	C445.715881,329.306854 444.603424,328.757416 443.150909,328.005981 
	C442.207458,322.763550 437.662933,322.574524 434.264679,321.230225 
	C433.641541,320.983673 431.938324,322.897339 431.042694,324.053314 
	C429.911774,325.513000 429.239685,328.463043 428.121094,328.590576 
	C424.346710,329.020966 422.813385,331.978638 420.445221,334.002777 
z"
          />
          <path
            fill="#161616"
            opacity="1.000000"
            stroke="none"
            d="
M534.649048,455.598877 
	C524.309448,462.462280 514.084961,462.635620 504.024414,456.113647 
	C494.890564,450.192444 491.493134,441.017090 492.455444,430.720642 
	C493.522583,419.302612 499.396149,410.841675 510.914825,407.172363 
	C520.858276,404.004852 532.498108,407.196594 539.001709,414.969788 
	C546.263794,423.649506 547.716797,436.582611 542.092773,446.651154 
	C540.284363,449.888763 537.362244,452.504303 534.649048,455.598877 
M535.860046,429.991821 
	C533.546448,416.470520 521.785950,410.244965 510.091187,416.634369 
	C502.465240,420.800812 501.386688,428.077301 501.841370,435.962952 
	C502.346405,444.721649 508.126404,451.200134 516.923157,452.464203 
	C524.864136,453.605286 532.833313,448.428986 535.070618,440.214935 
	C535.879150,437.246490 535.629822,433.989838 535.860046,429.991821 
z"
          />
          <path
            fill="#141414"
            opacity="1.000000"
            stroke="none"
            d="
M563.299683,448.941467 
	C563.299438,452.885193 563.299438,456.334930 563.299438,459.638947 
	C554.568848,461.516052 554.093018,461.144531 554.099487,453.204590 
	C554.110840,439.233398 554.208679,425.260956 554.036621,411.291931 
	C553.992371,407.693481 554.903137,406.091553 558.758545,406.361633 
	C565.201538,406.812988 571.746216,406.398621 578.104553,407.329529 
	C586.628357,408.577454 590.823059,413.038208 591.393311,419.862640 
	C592.224182,429.806885 589.565796,434.407715 581.005615,437.864502 
	C580.428711,438.097534 579.882568,438.406494 578.819336,438.925568 
	C583.158691,445.859680 587.343506,452.546753 591.572510,459.304504 
	C584.759705,461.695099 582.886475,461.092987 579.455566,455.645874 
	C576.620300,451.144409 574.054810,446.439880 570.850891,442.219269 
	C569.482971,440.417297 566.795532,439.617096 564.707703,438.361694 
	C564.238403,438.779327 563.769165,439.196991 563.299866,439.614655 
	C563.299866,442.558929 563.299866,445.503204 563.299683,448.941467 
M571.183289,432.664734 
	C572.829163,432.475677 574.492859,432.381805 576.117432,432.078613 
	C580.029968,431.348480 582.192627,428.752625 582.799866,425.000549 
	C583.436096,421.068512 582.357910,416.999115 578.523010,415.868011 
	C574.072571,414.555389 569.110168,414.453522 564.467163,414.881073 
	C563.764832,414.945740 563.580750,420.728394 563.178528,423.868927 
	C563.115845,424.358521 563.169495,424.863068 563.169189,425.360687 
	C563.164795,432.669159 563.164795,432.669159 571.183289,432.664734 
z"
          />
          <path
            fill="#121212"
            opacity="1.000000"
            stroke="none"
            d="
M134.785461,440.933197 
	C134.707596,447.269318 134.666733,453.095795 134.595673,458.921906 
	C134.592163,459.210785 134.325653,459.496490 134.024963,460.095947 
	C131.593887,460.095947 129.012894,460.095947 126.167114,460.095947 
	C126.167114,442.312439 126.167114,424.717499 126.167114,405.912781 
	C135.024368,406.516022 143.800171,406.526642 152.363159,407.881836 
	C159.268799,408.974731 162.759857,414.413544 163.373886,422.072754 
	C163.908356,428.739594 160.467560,434.440735 154.215591,437.212036 
	C153.170456,437.675323 152.093246,438.066284 150.229218,438.810883 
	C154.651184,445.780640 158.948685,452.554260 163.283600,459.386810 
	C156.282333,461.664520 154.590042,461.179749 151.133102,455.814758 
	C148.162552,451.204590 145.295059,446.527863 142.398102,441.870544 
	C140.384933,438.634094 137.935181,437.807068 134.785461,440.933197 
M134.706833,425.426208 
	C134.706833,427.678070 134.706833,429.929932 134.706833,432.074371 
	C145.339203,433.489899 151.503204,432.472412 152.999100,428.043549 
	C154.032715,424.983307 154.185013,420.146881 152.429520,417.912476 
	C147.944489,412.203949 141.118454,414.477997 134.706985,414.433075 
	C134.706985,418.175018 134.706985,421.317200 134.706833,425.426208 
z"
          />
          <path
            fill="#121212"
            opacity="1.000000"
            stroke="none"
            d="
M622.391602,440.472107 
	C628.035461,446.909790 633.451599,453.065521 639.470520,459.906342 
	C633.094299,460.720642 628.608582,461.131561 624.845520,455.714661 
	C620.455078,449.394623 614.836365,443.927856 608.445190,438.409454 
	C608.445190,445.539154 608.445190,452.668854 608.445190,460.082489 
	C604.836609,460.082489 602.100220,460.082489 599.092834,460.082489 
	C598.934814,458.803680 598.679443,457.691223 598.677612,456.578400 
	C598.652832,441.417114 598.791748,426.253998 598.576416,411.095764 
	C598.521179,407.204987 599.919495,406.363556 603.486755,406.306000 
	C607.283936,406.244781 608.430603,407.532501 608.240479,411.232758 
	C607.957275,416.743744 608.166992,422.280060 608.166992,427.805603 
	C608.724670,427.964966 609.282410,428.124298 609.840088,428.283661 
	C614.929077,422.498138 620.098938,416.781219 625.082336,410.906097 
	C629.308777,405.923462 631.566162,405.198792 638.859009,407.409088 
	C633.497986,413.489044 628.284851,419.463287 623.002747,425.375885 
	C616.506226,432.647858 616.481384,432.625641 622.391602,440.472107 
z"
          />
          <path
            fill="#141414"
            opacity="1.000000"
            stroke="none"
            d="
M227.604279,421.585052 
	C224.464813,416.667999 221.502106,412.066711 218.508835,407.417999 
	C225.698944,404.993652 228.152985,405.846313 231.848190,411.653229 
	C234.579224,415.945007 237.446075,420.150360 240.965179,425.472107 
	C244.112839,420.160583 246.627625,415.789856 249.266403,411.495270 
	C252.880020,405.614166 254.081284,405.240265 261.867371,407.433014 
	C257.565887,414.431946 253.577499,421.502411 248.992722,428.162292 
	C246.261810,432.129211 246.612793,435.145294 249.248123,438.895142 
	C253.903732,445.519684 258.126221,452.448639 262.610352,459.374329 
	C255.089554,461.671814 253.363068,461.147064 249.425232,455.348724 
	C246.386307,450.873993 243.480743,446.308685 239.938461,440.902405 
	C236.784515,446.161194 234.096542,450.467407 231.584503,454.873901 
	C228.059235,461.057770 226.486694,461.712799 218.834061,459.433228 
	C223.333588,451.981476 227.580215,444.494568 232.307190,437.324310 
	C234.460052,434.058716 234.788956,431.607452 232.128555,428.584778 
	C230.390961,426.610657 229.210846,424.145844 227.604279,421.585052 
z"
          />
          <path
            fill="#141414"
            opacity="1.000000"
            stroke="none"
            d="
M185.156815,448.322510 
	C180.162033,447.440826 179.630219,450.908264 178.562714,453.863831 
	C175.958511,461.073975 175.938889,461.066895 167.061310,459.868774 
	C169.881180,452.154022 172.622269,444.571869 175.423798,437.012054 
	C178.655212,428.292145 181.803238,419.535980 185.327682,410.934937 
	C185.960938,409.389465 188.209122,407.599091 189.769440,407.543579 
	C192.387573,407.450317 196.040695,405.631409 197.557281,409.743713 
	C203.536331,425.956146 209.473877,442.183899 215.406525,458.413391 
	C215.506714,458.687408 215.352188,459.054565 215.312759,459.377869 
	C208.809494,461.813141 206.765945,461.275085 205.054367,455.015259 
	C203.537598,449.467926 200.705811,447.441620 195.117676,448.238251 
	C192.016510,448.680389 188.800964,448.320160 185.156815,448.322510 
M195.128586,427.400055 
	C194.067993,424.450348 193.007401,421.500610 191.444611,417.154175 
	C188.416016,426.028198 185.901413,433.396240 183.259933,441.135986 
	C189.355667,441.135986 194.422623,441.135986 200.109161,441.135986 
	C198.393341,436.484955 196.856583,432.319305 195.128586,427.400055 
z"
          />
          <path
            fill="#121212"
            opacity="1.000000"
            stroke="none"
            d="
M366.740356,435.976379 
	C360.489960,436.232910 354.548798,436.232910 349.042114,436.232910 
	C348.462952,437.366882 348.211823,437.641022 348.190155,437.932220 
	C347.097534,452.605194 347.099670,452.604706 361.797241,452.577972 
	C364.277802,452.573486 366.758362,452.577332 369.612854,452.577332 
	C369.612854,455.102875 369.612854,457.317139 369.612854,460.412476 
	C363.448456,460.412476 357.218933,460.400726 350.989471,460.414917 
	C338.920837,460.442383 338.920319,460.448486 338.919800,448.614319 
	C338.919159,434.834930 338.919617,421.055511 338.919617,406.848114 
	C349.312683,406.848114 359.346954,406.848114 369.689514,406.848114 
	C369.689514,408.971405 369.689514,411.044708 369.689514,413.930450 
	C364.066803,413.930450 358.483490,414.156219 352.928741,413.854919 
	C348.896301,413.636139 347.592560,415.137146 347.926971,419.016632 
	C348.208038,422.277405 347.984741,425.581635 347.984741,429.428406 
	C354.631378,429.428406 360.710754,429.428406 367.412872,429.428406 
	C367.268524,431.928589 367.159088,433.824219 366.740356,435.976379 
z"
          />
          <path
            fill="#098709"
            opacity="1.000000"
            stroke="none"
            d="
M358.788330,160.725082 
	C359.604828,159.007355 360.421295,157.289627 361.690643,155.132111 
	C362.449829,153.859055 362.756104,153.025787 363.062378,152.192535 
	C365.106537,150.442261 367.150665,148.692001 369.955200,147.013519 
	C373.760010,147.738876 376.804382,148.392456 379.848785,149.046051 
	C380.161652,149.216064 380.474548,149.386078 380.863403,150.088318 
	C381.424469,151.117874 381.909546,151.615204 382.394623,152.112549 
	C382.643768,152.435669 382.892914,152.758804 383.096741,153.754990 
	C384.527740,157.013351 386.003998,159.598648 387.480286,162.183929 
	C387.480286,162.183929 387.729858,162.346527 387.713257,162.848145 
	C389.114380,166.902512 390.532074,170.455276 391.563385,174.014145 
	C390.097107,175.201508 388.491180,176.205032 388.022430,177.592712 
	C384.894043,186.854309 383.154419,196.275879 385.785400,205.843903 
	C384.984100,205.098999 384.400421,204.498596 383.816772,203.898193 
	C383.530823,203.549088 383.244904,203.199966 382.986572,202.231323 
	C382.581177,201.053268 382.148163,200.494766 381.715149,199.936264 
	C381.270111,199.254715 380.825104,198.573151 380.161377,197.584717 
	C379.942719,197.277832 379.937592,197.432785 379.937592,197.432785 
	C378.613617,194.565155 377.289612,191.697540 375.984497,188.189911 
	C375.570160,187.013885 375.136932,186.477875 374.703735,185.941864 
	C374.539520,185.776337 374.375336,185.610809 374.035950,184.832886 
	C372.910004,184.367722 371.959290,184.514969 371.008545,184.662216 
	C370.182098,186.647186 369.355621,188.632141 367.976807,190.888489 
	C366.947845,191.453629 366.471161,191.747391 366.002136,191.647949 
	C366.350098,187.423737 366.669800,183.590790 367.035675,179.762238 
	C367.569580,174.175049 362.867737,168.198303 357.041931,166.657959 
	C357.665375,164.438217 358.226868,162.581650 358.788330,160.725082 
z"
          />
          <path
            fill="#3EE949"
            opacity="1.000000"
            stroke="none"
            d="
M458.972961,294.987244 
	C455.986084,295.951691 452.079712,302.275818 451.981781,305.454346 
	C451.904877,307.948608 451.079529,310.419800 450.586700,312.901245 
	C449.833923,312.971191 449.081116,313.041138 448.328339,313.111084 
	C447.257599,310.570435 445.921997,308.104004 445.183655,305.470184 
	C444.274536,302.227234 444.835632,298.146057 443.048065,295.658081 
	C438.330505,289.092072 442.246704,284.125092 445.288086,278.300049 
	C446.100006,276.867279 446.501129,275.866180 446.958374,274.665588 
	C447.014526,274.466125 447.207397,274.099274 447.543243,274.040039 
	C451.693665,271.560364 452.729248,267.854004 452.704102,263.460999 
	C452.743652,263.283478 452.963348,262.993530 453.328369,262.942566 
	C453.904205,261.777710 454.451447,260.602966 454.263184,259.561188 
	C453.685577,256.364960 453.593109,252.649292 457.412628,252.633911 
	C460.250763,252.622498 464.306519,254.635818 465.662079,256.989899 
	C467.710358,260.546844 467.879425,265.185883 468.856262,269.404694 
	C468.149750,269.207428 466.796204,268.829468 465.442657,268.451538 
	C465.099884,268.803894 464.757111,269.156250 464.414337,269.508606 
	C465.873016,271.676971 467.331696,273.845306 468.815674,276.325623 
	C468.872406,277.052704 468.903778,277.467834 468.935181,277.882935 
	C468.819427,278.339081 468.703644,278.795227 468.113708,279.614380 
	C466.747498,280.648193 465.855438,281.318970 464.641968,282.038666 
	C463.885651,283.395264 463.450775,284.703003 463.007019,286.347595 
	C462.637909,287.448700 462.277679,288.212921 461.602051,289.033020 
	C460.846588,290.391815 460.406525,291.694672 459.950165,293.293945 
	C459.613586,294.055969 459.293274,294.521606 458.972961,294.987244 
z"
          />
          <path
            fill="#36D441"
            opacity="1.000000"
            stroke="none"
            d="
M435.001221,258.007568 
	C434.118835,261.267456 433.041779,264.492645 432.451904,267.804626 
	C432.222626,269.091766 433.060425,270.584473 433.470825,271.966125 
	C433.975128,273.663849 434.540771,275.343353 435.079926,277.030701 
	C434.623779,277.419312 434.167664,277.807922 433.711517,278.196564 
	C432.812592,277.287964 431.572052,276.531769 431.084930,275.439545 
	C430.026642,273.066650 429.640411,270.364868 428.406525,268.111237 
	C427.744049,266.901215 425.895538,266.340576 424.582214,265.486908 
	C424.464600,267.225739 423.585052,269.535583 424.377197,270.592651 
	C426.462830,273.375671 426.226257,275.157349 423.325623,277.093658 
	C420.795654,273.645050 418.407471,270.389709 415.707703,267.063507 
	C415.270691,266.851593 415.145203,266.710541 414.989929,265.936890 
	C413.765320,263.534180 412.570557,261.764099 411.375793,259.994019 
	C411.244995,259.876923 411.114197,259.759796 410.961151,259.009888 
	C407.785461,252.580261 404.632019,246.783447 401.478607,240.986633 
	C401.191895,240.405884 400.905182,239.825150 400.750427,238.535995 
	C400.651398,236.850571 400.420502,235.873566 400.565033,234.913574 
	C401.959991,233.125137 403.665802,231.389206 403.857147,229.499893 
	C404.263733,225.484589 406.694580,223.637253 409.824799,221.990814 
	C411.327057,221.200638 412.616913,220.006668 414.273254,219.215805 
	C416.728363,225.249954 418.913239,231.063873 421.032196,237.245132 
	C419.120941,240.311584 418.372986,242.767685 421.561798,245.254486 
	C422.505341,245.990356 422.781952,247.848160 422.967804,249.244568 
	C423.992340,256.943390 425.145569,257.996552 433.003204,257.999786 
	C433.665955,258.000061 434.328705,257.999115 434.995209,257.999390 
	C434.998962,258.000031 435.001221,258.007568 435.001221,258.007568 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M365.994507,192.041153 
	C366.471161,191.747391 366.947845,191.453629 367.699646,191.111847 
	C362.614349,201.925964 357.322449,212.822571 351.878540,223.642700 
	C342.155609,242.967575 332.280426,262.216003 322.598999,281.561554 
	C319.406677,287.940491 316.478638,294.463348 313.739899,301.049377 
	C313.072144,302.655212 313.114410,304.962494 313.730927,306.610260 
	C314.948090,309.863129 316.902740,312.831299 318.350281,316.009247 
	C319.715973,319.007538 322.513153,321.678284 320.294434,325.620819 
	C319.833954,326.439117 321.101227,328.365082 321.783905,329.669800 
	C327.029327,339.695190 332.349030,349.681732 337.588623,359.710144 
	C338.554810,361.559296 339.262695,363.543365 340.493896,366.404114 
	C337.465149,366.404114 335.360565,366.404114 332.356873,366.209900 
	C329.808105,366.077545 328.158447,366.139374 326.508789,366.201202 
	C321.441833,366.272430 316.374908,366.343658 310.392029,366.218262 
	C308.491333,366.080414 307.506592,366.139160 306.521851,366.197937 
	C293.963898,366.235352 281.405945,366.272797 268.434509,365.781494 
	C268.027985,364.818024 268.034882,364.383331 268.365295,363.968628 
	C269.121368,363.992798 269.553986,363.997009 270.455963,364.013733 
	C289.697388,364.032684 308.469666,364.097595 327.241333,364.016815 
	C334.742432,363.984558 335.673615,362.400848 332.296753,356.404938 
	C331.654205,355.264160 331.413635,353.902039 330.812134,352.732269 
	C327.582550,346.451477 324.297424,340.199219 320.982391,333.575562 
	C319.956146,329.799316 318.815460,326.420898 318.048523,322.959656 
	C317.306152,319.609070 317.333191,315.853851 313.370148,316.278076 
	C309.605774,311.577026 309.657654,306.547699 310.009094,301.477966 
	C310.379883,296.129059 310.781464,290.782318 311.169586,285.434631 
	C311.715668,285.378418 312.261719,285.322235 312.807770,285.266052 
	C313.459137,287.358032 314.110474,289.450012 314.761841,291.541992 
	C315.263550,291.649994 315.765228,291.757996 316.266907,291.866028 
	C317.528931,287.936157 318.790955,284.006317 320.310394,279.836548 
	C322.120636,276.486145 323.745148,273.408966 325.211304,270.258118 
	C328.188232,263.860596 331.079529,257.423218 334.162903,250.655823 
	C335.631989,248.050110 337.054169,245.846222 338.228088,243.517227 
	C339.621185,240.753448 340.800659,237.882004 342.380798,234.888275 
	C343.419037,233.828415 344.328613,233.031342 344.851440,232.031952 
	C349.905182,222.371780 354.893890,212.677597 360.070251,202.696930 
	C361.186157,200.629868 362.132965,198.858994 363.330811,196.880737 
	C364.386078,195.129288 365.190277,193.585220 365.994507,192.041153 
z"
          />
          <path
            fill="#121212"
            opacity="1.000000"
            stroke="none"
            d="
M398.129028,440.999634 
	C398.128906,447.618225 398.128906,453.737061 398.128906,460.125793 
	C395.164551,460.125793 392.736389,460.125793 389.542297,460.125793 
	C389.542297,444.870972 389.542297,429.679779 389.542297,414.007782 
	C385.153198,414.007782 381.519012,413.914154 377.892029,414.036224 
	C374.757385,414.141724 374.523407,412.296448 374.457123,409.850708 
	C374.384979,407.188995 375.516785,406.358459 378.021484,406.390045 
	C388.505890,406.522247 398.994385,406.455200 409.472595,406.762451 
	C410.531403,406.793518 412.067078,408.702118 412.435577,410.006622 
	C413.031952,412.117767 412.337982,413.952942 409.446411,413.876373 
	C405.836945,413.780731 402.223053,413.853394 398.129120,413.853394 
	C398.129120,423.253967 398.129120,431.876923 398.129028,440.999634 
z"
          />
          <path
            fill="#51EB5A"
            opacity="1.000000"
            stroke="none"
            d="
M468.790375,276.013672 
	C467.331696,273.845306 465.873016,271.676971 464.414337,269.508606 
	C464.757111,269.156250 465.099884,268.803894 465.442657,268.451538 
	C466.796204,268.829468 468.149750,269.207428 468.856262,269.404694 
	C467.879425,265.185883 467.710358,260.546844 465.662079,256.989899 
	C464.306519,254.635818 460.250763,252.622498 457.412628,252.633911 
	C453.593109,252.649292 453.685577,256.364960 454.263184,259.561188 
	C454.451447,260.602966 453.904205,261.777710 453.244232,262.664825 
	C452.530457,261.938843 452.265839,261.439667 451.926270,260.564026 
	C449.572449,259.790466 447.293549,259.393402 445.014648,258.996338 
	C445.012512,258.554077 445.010376,258.111816 445.009827,256.945862 
	C444.783051,255.124878 444.891968,253.759781 444.269318,252.975510 
	C441.198669,249.107773 440.631195,244.700439 441.420227,239.991196 
	C445.302979,239.599915 448.770844,239.231735 452.238739,238.863525 
	C453.820892,238.818222 455.403076,238.772919 457.857239,238.882736 
	C462.377167,239.058289 466.028412,238.985825 469.672302,239.117630 
	C478.498596,239.436905 483.089630,246.551208 479.614777,254.605652 
	C476.823303,261.076172 473.842743,267.465149 470.946045,273.890289 
	C470.815918,274.355377 470.685822,274.820496 470.105896,275.586395 
	C469.367737,275.931000 469.079163,275.973145 468.790375,276.013672 
z"
          />
          <path
            fill="#37C343"
            opacity="1.000000"
            stroke="none"
            d="
M268.041809,363.948608 
	C268.034882,364.383331 268.027985,364.818024 268.002228,365.578247 
	C261.832153,363.254364 259.961304,357.639587 263.289520,350.862701 
	C269.967163,337.265625 276.589935,323.633331 283.683990,310.253418 
	C286.245758,305.421722 286.091705,301.642853 283.537781,296.861572 
	C276.326111,283.360291 269.625946,269.584045 262.819366,255.868912 
	C259.078491,248.331085 261.640808,241.942596 269.725769,239.391068 
	C270.107086,239.675934 270.172638,240.125351 270.122314,240.470490 
	C271.352386,241.894943 272.632812,242.974228 273.919586,244.368469 
	C273.921906,245.103424 273.917847,245.523422 273.571930,245.958984 
	C272.470947,246.613281 271.711853,247.252014 270.683350,248.141571 
	C267.547638,254.942810 270.579132,261.317719 271.233948,267.717163 
	C271.453217,269.860077 273.908661,271.692017 274.984802,273.844635 
	C278.398254,280.672821 281.193085,287.850586 285.056122,294.404541 
	C291.234802,304.887299 291.091675,306.344666 284.703949,313.133545 
	C282.585388,316.850189 280.771362,320.406586 278.691620,324.193909 
	C273.941772,333.613159 269.457642,342.801575 264.653198,352.182800 
	C262.766663,355.063934 261.443573,357.733887 265.067810,360.316467 
	C266.163116,361.710144 267.102448,362.829376 268.041809,363.948608 
z"
          />
          <path
            fill="#71E577"
            opacity="1.000000"
            stroke="none"
            d="
M458.936432,295.337830 
	C459.293274,294.521606 459.613586,294.055969 460.248657,293.128601 
	C461.014771,291.436981 461.466125,290.207062 461.917480,288.977112 
	C462.277679,288.212921 462.637909,287.448700 463.350189,286.261963 
	C464.122620,284.556213 464.542999,283.273010 464.963379,281.989777 
	C465.855438,281.318970 466.747498,280.648193 467.895020,279.894135 
	C465.822510,285.207001 463.843903,290.791992 461.059937,295.941589 
	C458.687012,300.330841 458.557953,303.795074 461.031342,308.399841 
	C468.409210,322.135193 475.053284,336.262848 482.101013,350.178040 
	C486.849884,359.554291 480.303925,368.111542 471.486359,367.767670 
	C451.039917,366.970245 430.541046,367.531982 410.063538,367.489441 
	C408.457581,367.486084 406.852478,367.068237 405.090179,366.350220 
	C404.933380,365.857422 405.069611,365.577576 405.522034,365.684875 
	C428.341156,365.819397 450.707947,365.816559 473.074371,365.904327 
	C476.680695,365.918457 479.107513,364.614197 480.391663,360.758057 
	C482.789490,353.364746 478.199677,348.355652 475.163757,342.485535 
	C473.464935,338.718109 471.792389,335.320435 470.058502,331.584717 
	C469.015289,330.136932 468.033417,329.027222 467.033356,327.611267 
	C466.717926,326.785736 466.420654,326.266479 466.123413,325.747192 
	C463.833893,320.346039 461.860962,314.780182 459.161957,309.592010 
	C456.678436,304.818085 455.691956,300.392090 458.936432,295.337830 
z"
          />
          <path
            fill="#3CAE3F"
            opacity="1.000000"
            stroke="none"
            d="
M270.172638,240.125351 
	C270.172638,240.125351 270.107086,239.675934 270.087280,239.449738 
	C274.807495,238.870010 279.543457,238.294846 284.288391,238.208038 
	C294.928436,238.013351 305.586609,238.327789 316.203827,237.781982 
	C318.283783,237.675064 320.988312,235.016357 322.088104,232.862335 
	C331.097748,215.216202 339.771667,197.398941 348.600800,179.660156 
	C351.715759,173.401855 354.978607,167.217194 358.481140,160.862000 
	C358.226868,162.581650 357.665375,164.438217 356.770721,166.871490 
	C353.936066,172.971008 351.434570,178.493790 348.781036,184.325119 
	C344.384369,194.019730 340.139740,203.405792 335.546509,212.883301 
	C331.453949,220.268280 327.838806,227.632812 323.883667,234.809982 
	C322.768066,236.834396 320.656677,238.310059 318.587738,240.024384 
	C316.791870,240.022171 315.410309,240.032990 313.607361,240.027863 
	C311.781067,240.021790 310.376221,240.031693 308.635284,240.040497 
	C307.851990,240.065094 307.404785,240.090775 306.505615,240.082825 
	C302.722565,240.085938 299.391418,240.122665 295.685150,240.112915 
	C294.556549,240.054565 293.803101,240.042694 292.613525,240.018311 
	C290.438965,240.008774 288.700531,240.011765 286.585876,240.012924 
	C285.455078,240.034943 284.700531,240.058807 283.501282,240.069626 
	C280.386444,240.060257 277.716339,240.063934 274.636047,240.044464 
	C272.874786,240.056015 271.523712,240.090683 270.172638,240.125351 
z"
          />
          <path
            fill="#36D441"
            opacity="1.000000"
            stroke="none"
            d="
M420.230621,334.197327 
	C422.813385,331.978638 424.346710,329.020966 428.121094,328.590576 
	C429.239685,328.463043 429.911774,325.513000 431.042694,324.053314 
	C431.938324,322.897339 433.641541,320.983673 434.264679,321.230225 
	C437.662933,322.574524 442.207458,322.763550 443.061371,328.362549 
	C441.018494,333.313202 438.725159,337.705261 436.026215,342.081268 
	C433.558960,341.737946 431.497284,341.410706 429.435638,341.083466 
	C426.863251,341.108917 423.837341,344.315857 422.383240,338.734955 
	C422.267487,338.290710 420.321564,338.323334 419.287354,337.811646 
	C419.552887,336.648865 419.757294,335.808777 419.961670,334.968689 
	C419.979767,334.776428 419.997894,334.584167 420.230621,334.197327 
z"
          />
          <path
            fill="#0D9F0D"
            opacity="1.000000"
            stroke="none"
            d="
M386.003082,205.988419 
	C383.154419,196.275879 384.894043,186.854309 388.022430,177.592712 
	C388.491180,176.205032 390.097107,175.201508 391.735626,174.320160 
	C394.239838,179.063766 396.185394,183.507507 397.765198,187.996918 
	C390.629395,193.355362 388.700897,210.280136 393.985138,218.365753 
	C393.589355,219.516937 393.193481,220.310806 392.797607,221.104675 
	C392.188995,220.312729 391.580383,219.520767 391.022156,218.067566 
	C390.178955,215.944717 389.285431,214.483139 388.391907,213.021545 
	C387.938782,212.317963 387.485657,211.614365 386.991516,210.235809 
	C386.634705,208.370026 386.318909,207.179214 386.003082,205.988419 
z"
          />
          <path
            fill="#404040"
            opacity="1.000000"
            stroke="none"
            d="
M371.409058,487.452637 
	C371.698090,492.578552 367.442627,495.351013 360.873627,494.620605 
	C355.972168,494.075653 352.749756,490.127045 352.595856,484.477325 
	C352.419373,478.000732 355.293060,473.694702 360.341461,472.870789 
	C366.545776,471.858307 370.553070,474.104706 372.535461,480.682404 
	C370.541229,479.530365 369.212860,478.948608 368.101410,478.087036 
	C364.371307,475.195435 360.518890,475.130615 357.868744,478.040466 
	C354.842102,481.363647 354.964905,486.495728 358.149414,489.768311 
	C360.889374,492.584076 364.615173,492.308228 368.283356,489.115051 
	C369.109100,488.396210 370.155823,487.931213 371.409058,487.452637 
z"
          />
          <path
            fill="#404040"
            opacity="1.000000"
            stroke="none"
            d="
M133.597260,475.571228 
	C132.088715,476.372223 130.010330,477.390564 130.077637,478.238556 
	C130.177368,479.494781 131.701645,480.734528 132.814087,481.772766 
	C133.363861,482.285858 134.573196,482.107635 135.097214,482.630188 
	C137.064407,484.591888 139.581543,486.461029 140.442581,488.880798 
	C140.877396,490.102814 138.366821,493.799072 136.905319,493.970856 
	C133.919601,494.321655 130.678299,493.250092 127.685997,492.307861 
	C126.848419,492.044098 126.512581,490.186951 125.946587,489.060730 
	C126.285828,488.668030 126.625069,488.275360 126.964310,487.882660 
	C128.098618,488.708801 129.150940,489.699280 130.389236,490.316956 
	C131.754028,490.997803 133.324188,491.765717 134.736847,491.651855 
	C135.499649,491.590332 136.887650,489.494141 136.639984,488.796173 
	C136.173325,487.480896 134.871841,486.302612 133.646973,485.460876 
	C132.450089,484.638367 130.844254,484.438477 129.544769,483.731964 
	C126.871002,482.278290 124.985222,480.234192 126.029861,476.899872 
	C127.201050,473.161560 130.201859,472.573914 133.603241,472.825958 
	C136.779327,473.061340 139.472656,473.819244 139.526917,479.025604 
	C137.237274,477.656189 135.612244,476.684296 133.597260,475.571228 
z"
          />
          <path
            fill="#505050"
            opacity="1.000000"
            stroke="none"
            d="
M423.131989,486.896454 
	C422.578674,489.730804 422.027039,492.106720 421.475403,494.482605 
	C420.831116,492.047333 419.885254,489.644409 419.619720,487.168518 
	C419.295532,484.145782 419.542572,481.061829 419.542572,478.606476 
	C422.824799,477.995209 425.084412,477.468750 427.373779,477.172638 
	C430.865509,476.721069 433.977966,477.875458 434.797699,481.394470 
	C435.644806,485.030762 435.549377,488.906311 435.628876,492.682190 
	C435.641968,493.303864 434.435272,493.951263 433.792358,494.586792 
	C433.100769,493.816589 431.966644,493.126221 431.810242,492.259460 
	C431.490967,490.490051 431.760559,488.622650 431.673462,486.801086 
	C431.539948,484.007019 431.857697,480.603821 427.927734,480.340576 
	C423.842865,480.066925 423.287842,483.254608 423.131989,486.896454 
z"
          />
          <path
            fill="#505050"
            opacity="1.000000"
            stroke="none"
            d="
M517.426147,489.379883 
	C520.251160,492.340363 522.695129,492.376892 525.264404,489.550293 
	C526.042419,488.694305 527.466431,488.425476 529.108459,487.642731 
	C528.189636,493.267548 525.855042,494.995758 520.732544,494.723602 
	C516.035645,494.473999 513.243774,491.692932 512.751770,486.773773 
	C512.288086,482.137604 515.553711,477.921143 520.235352,477.111542 
	C524.451599,476.382416 527.738831,478.729004 529.366150,484.076630 
	C528.128967,483.934845 526.620667,484.195557 526.205261,483.639679 
	C524.363831,481.174927 522.478699,479.173828 519.212463,481.056396 
	C515.916382,482.956177 516.458740,485.985535 517.426147,489.379883 
z"
          />
          <path
            fill="#71E577"
            opacity="1.000000"
            stroke="none"
            d="
M452.022980,238.549957 
	C448.770844,239.231735 445.302979,239.599915 441.000305,239.981979 
	C437.775269,240.300949 435.385071,240.606033 432.610870,240.864288 
	C428.517303,239.504227 424.807678,238.191010 421.098083,236.877808 
	C418.913239,231.063873 416.728363,225.249954 414.230713,218.859055 
	C412.234192,215.209579 410.550476,212.137070 408.792419,208.734558 
	C408.109894,206.969604 407.501678,205.534668 406.886108,203.734436 
	C405.632050,200.199738 404.385315,197.030365 403.138550,193.860992 
	C406.166382,199.313751 409.324524,204.699036 412.190857,210.235413 
	C416.302612,218.177383 420.231232,226.214935 424.178558,234.240952 
	C426.097107,238.141953 429.428772,237.997665 433.069641,237.927292 
	C439.311188,237.806671 445.560913,238.110657 452.022980,238.549957 
z"
          />
          <path
            fill="#03AC07"
            opacity="1.000000"
            stroke="none"
            d="
M393.985046,218.008423 
	C388.700897,210.280136 390.629395,193.355362 398.005493,188.213837 
	C399.276062,188.802200 399.940643,189.219315 400.605194,189.636429 
	C400.605194,189.636429 400.880432,189.748444 400.835266,190.196686 
	C400.835602,191.105118 400.881042,191.565338 400.917725,192.398682 
	C400.962860,193.516769 401.016724,194.261719 400.769470,195.268250 
	C399.147583,201.024017 397.917786,206.542526 396.459534,211.999985 
	C395.905060,214.075043 394.825378,216.009750 393.985046,218.008423 
z"
          />
          <path
            fill="#464646"
            opacity="1.000000"
            stroke="none"
            d="
M393.963440,477.462524 
	C401.116028,477.160614 404.426208,480.284607 404.137817,486.640991 
	C403.888397,492.139221 400.211334,495.187256 394.476959,494.657898 
	C390.674774,494.306915 388.368225,492.187897 387.703796,488.512634 
	C386.648621,482.675629 388.268951,479.832703 393.963440,477.462524 
M391.250214,488.758850 
	C394.659454,492.727478 398.873566,492.963074 400.030975,488.968781 
	C400.668549,486.768524 399.991791,483.868988 399.023010,481.675385 
	C398.625336,480.774902 395.685883,480.243317 394.270447,480.695465 
	C390.826782,481.795441 390.320770,484.762115 391.250214,488.758850 
z"
          />
          <path
            fill="#404040"
            opacity="1.000000"
            stroke="none"
            d="
M293.211365,491.026947 
	C290.437195,495.302948 286.334747,495.589172 282.254395,493.917419 
	C278.497711,492.378357 277.024323,488.946533 277.512604,484.870392 
	C278.004669,480.762695 280.273376,478.273376 284.381714,477.388214 
	C289.363953,476.314728 292.556061,478.860565 293.610901,485.797882 
	C289.112976,486.727509 284.977905,487.055573 279.833557,487.463715 
	C281.508698,489.181488 282.807709,491.496521 283.832703,491.380951 
	C286.950165,491.029480 289.961823,489.739441 292.989807,488.822388 
	C293.048828,489.409546 293.130096,490.218262 293.211365,491.026947 
M281.313354,484.629578 
	C284.045013,484.754639 286.776642,484.879700 289.508270,485.004761 
	C289.828308,484.595917 290.148346,484.187073 290.468384,483.778229 
	C289.461243,482.591370 288.661499,480.744080 287.405518,480.349701 
	C284.425842,479.413910 281.927887,480.394928 281.313354,484.629578 
z"
          />
          <path
            fill="#404040"
            opacity="1.000000"
            stroke="none"
            d="
M594.903564,494.624756 
	C588.833923,493.912659 585.773132,490.592987 586.140259,485.545105 
	C586.465820,481.070740 590.315735,477.161346 594.520508,477.035461 
	C598.719727,476.909698 602.772034,480.686249 603.221497,485.144257 
	C603.739258,490.279419 601.044617,493.502533 594.903564,494.624756 
M595.994751,480.363861 
	C592.835083,479.637848 590.438599,480.970459 590.241394,484.002960 
	C590.089722,486.334320 590.937561,489.179871 592.372559,490.969666 
	C593.056885,491.823181 597.588928,491.260010 597.868408,490.460938 
	C598.750488,487.938416 598.545227,484.999878 598.510559,482.225433 
	C598.504211,481.721985 597.316895,481.233276 595.994751,480.363861 
z"
          />
          <path
            fill="#505050"
            opacity="1.000000"
            stroke="none"
            d="
M457.122375,480.615417 
	C456.402985,483.404053 455.727386,486.204956 454.948975,488.977020 
	C454.423676,490.847595 453.753387,492.677429 453.148010,494.525513 
	C452.461395,492.619965 451.373108,490.754028 451.180176,488.799744 
	C450.872772,485.686188 451.138000,482.513092 451.225403,479.366943 
	C451.237579,478.928558 451.615143,478.500336 451.635376,478.458344 
	C454.481049,477.939606 456.974945,477.242004 459.503082,477.080292 
	C463.444427,476.828217 466.594208,478.700623 467.129791,482.619873 
	C467.630981,486.287476 466.926544,490.119843 466.378693,493.511780 
	C466.004669,490.454315 466.004669,487.764801 466.004669,485.075287 
	C465.670288,485.082642 465.335907,485.089996 465.001526,485.097382 
	C465.001526,488.059174 465.001526,491.020996 465.001526,493.982788 
	C464.586151,491.774078 464.380310,489.498322 463.700409,487.374298 
	C462.918304,484.931000 461.716888,482.621918 460.306030,479.778687 
	C458.983795,479.740356 458.053070,480.177887 457.122375,480.615417 
z"
          />
          <path
            fill="#21CB32"
            opacity="1.000000"
            stroke="none"
            d="
M408.866730,209.064575 
	C410.550476,212.137070 412.234192,215.209579 413.960449,218.638824 
	C412.616913,220.006668 411.327057,221.200638 409.824799,221.990814 
	C406.694580,223.637253 404.263733,225.484589 403.857147,229.499893 
	C403.665802,231.389206 401.959991,233.125137 400.480804,234.631500 
	C399.467682,233.878235 398.914246,233.424042 398.360779,232.969849 
	C397.847137,231.824402 397.333466,230.678940 396.897217,229.113922 
	C396.974640,228.694366 397.066986,228.656906 397.443634,228.764969 
	C398.830475,228.209808 400.483246,227.738480 400.747772,226.852600 
	C402.635468,220.530991 404.963959,214.450485 408.866730,209.064575 
z"
          />
          <path
            fill="#404040"
            opacity="1.000000"
            stroke="none"
            d="
M262.564148,491.644287 
	C262.986481,492.619995 263.020996,493.538818 263.055511,494.457672 
	C261.242950,493.941040 258.219025,493.882477 257.821350,492.830841 
	C255.777512,487.425842 254.325241,481.752533 255.967972,475.892273 
	C256.836884,472.792542 258.220673,472.879181 259.891602,475.455750 
	C260.818420,476.884918 261.941406,478.186890 262.877014,479.416138 
	C259.418823,481.771545 258.728149,487.910187 262.564148,491.644287 
z"
          />
          <path
            fill="#464646"
            opacity="1.000000"
            stroke="none"
            d="
M158.154572,478.549316 
	C162.165985,477.729309 166.241272,475.895844 169.969482,479.456329 
	C170.154388,479.632904 171.289062,478.524353 172.006775,478.485901 
	C174.598160,478.346985 177.200394,478.409943 179.596436,478.766785 
	C178.937927,479.732819 178.481522,480.328735 178.025116,480.924622 
	C172.950745,479.788940 170.848862,481.258453 170.598343,486.293182 
	C170.465332,488.966492 170.574783,491.651825 170.574783,494.331726 
	C169.774429,494.373047 168.974091,494.414368 168.173737,494.455688 
	C167.951614,491.468353 167.748047,488.479462 167.503220,485.493988 
	C167.094833,480.514099 164.360825,479.239349 159.430679,481.825226 
	C158.672867,480.654510 158.413712,479.601898 158.154572,478.549316 
z"
          />
          <path
            fill="#464646"
            opacity="1.000000"
            stroke="none"
            d="
M492.968353,494.827820 
	C492.230591,494.792114 491.492798,494.756378 490.133545,494.590881 
	C488.665985,494.324615 487.819916,494.188080 486.973877,494.051544 
	C486.558167,493.718536 486.142456,493.385498 485.399414,492.659546 
	C484.555786,491.643707 484.039459,491.020782 483.523132,490.397827 
	C483.417297,490.077240 483.311462,489.756622 483.101685,488.819763 
	C483.267914,485.782532 483.538147,483.361572 483.808411,480.940582 
	C490.344238,474.582153 496.968781,476.296356 499.467834,485.496887 
	C495.488251,487.170410 495.612305,487.168213 495.546509,483.468018 
	C495.530365,482.559082 494.401215,480.897614 493.807922,480.908417 
	C491.545746,480.949615 487.695129,480.998108 487.353424,482.019379 
	C486.511414,484.536011 486.674805,487.847626 487.706970,490.304932 
	C488.114655,491.275513 491.928406,491.125671 494.140839,490.926392 
	C495.801361,490.776764 497.381317,489.732727 498.997131,489.086975 
	C497.877808,490.658142 496.758453,492.229340 495.075348,494.004333 
	C493.997223,494.414703 493.482788,494.621277 492.968353,494.827820 
z"
          />
          <path
            fill="#505050"
            opacity="1.000000"
            stroke="none"
            d="
M544.957031,485.835815 
	C544.494080,483.336639 544.031433,481.270844 543.271912,477.879822 
	C543.932007,477.236237 545.616577,475.593719 547.301208,473.951172 
	C548.826233,475.574951 550.351196,477.198700 551.363037,478.276062 
	C550.351196,480.721008 548.551270,483.164673 548.544312,485.613434 
	C548.537048,488.152313 550.266785,490.696136 551.451538,493.800110 
	C549.601807,495.542175 546.245605,494.844635 545.249084,491.205383 
	C544.823242,489.649963 545.035095,487.919891 544.957031,485.835815 
z"
          />
          <path
            fill="#15B821"
            opacity="1.000000"
            stroke="none"
            d="
M393.985138,218.365753 
	C394.825378,216.009750 395.905060,214.075043 396.459534,211.999985 
	C397.917786,206.542526 399.147583,201.024017 401.031189,195.509094 
	C402.408447,197.650726 403.222870,199.813080 403.785339,202.168930 
	C402.898010,204.201752 402.291229,206.051590 401.617889,207.876877 
	C401.125275,209.212326 400.234467,210.476273 400.062164,211.840439 
	C399.544769,215.936691 399.373016,220.076553 398.856720,224.173004 
	C398.771576,224.848450 397.629303,225.390625 396.835754,225.715866 
	C395.825256,224.963303 394.954590,224.489288 394.083923,224.015259 
	C393.663666,223.267822 393.243378,222.520386 392.810364,221.438828 
	C393.193481,220.310806 393.589355,219.516937 393.985138,218.365753 
z"
          />
          <path
            fill="#71E577"
            opacity="1.000000"
            stroke="none"
            d="
M471.271973,273.829041 
	C473.842743,267.465149 476.823303,261.076172 479.614777,254.605652 
	C483.089630,246.551208 478.498596,239.436905 469.672302,239.117630 
	C466.028412,238.985825 462.377167,239.058289 458.247620,238.952621 
	C463.363190,235.945908 475.281586,237.226562 479.293396,241.463730 
	C482.157990,244.489227 484.078552,248.356201 482.264191,252.418396 
	C479.022980,259.675201 475.185822,266.665863 471.271973,273.829041 
z"
          />
          <path
            fill="#D3D3D3"
            opacity="1.000000"
            stroke="none"
            d="
M499.245667,488.913391 
	C497.381317,489.732727 495.801361,490.776764 494.140839,490.926392 
	C491.928406,491.125671 488.114655,491.275513 487.706970,490.304932 
	C486.674805,487.847626 486.511414,484.536011 487.353424,482.019379 
	C487.695129,480.998108 491.545746,480.949615 493.807922,480.908417 
	C494.401215,480.897614 495.530365,482.559082 495.546509,483.468018 
	C495.612305,487.168213 495.488251,487.170410 499.501343,485.846741 
	C499.737701,486.764252 499.615936,487.752014 499.245667,488.913391 
z"
          />
          <path
            fill="#464646"
            opacity="1.000000"
            stroke="none"
            d="
M317.741974,477.850586 
	C317.741333,478.987305 317.625824,480.580414 317.304230,480.623169 
	C311.412537,481.406250 312.496124,485.829987 312.240692,489.720398 
	C312.136780,491.303558 311.255981,492.835754 310.728638,494.391113 
	C310.136475,492.926117 309.125702,491.486877 309.033508,489.991089 
	C308.792236,486.075043 308.952148,482.134277 308.952148,478.350922 
	C312.221069,478.059174 314.876129,477.822266 317.741974,477.850586 
z"
          />
          <path
            fill="#505050"
            opacity="1.000000"
            stroke="none"
            d="
M236.633179,478.137024 
	C238.136749,478.127380 239.290527,478.268127 240.444275,478.408844 
	C239.758194,479.294098 239.234329,480.697113 238.357010,480.972015 
	C235.134201,481.981842 234.580154,484.269165 234.598633,487.167664 
	C234.614395,489.643311 234.362442,492.120697 233.343307,494.586823 
	C230.121323,489.198792 231.562607,483.568115 231.489502,477.680878 
	C233.519241,477.937653 234.901306,478.112518 236.633179,478.137024 
z"
          />
          <path
            fill="#404040"
            opacity="1.000000"
            stroke="none"
            d="
M623.083984,478.916779 
	C630.033020,476.159393 634.054382,478.630768 634.264832,485.816559 
	C634.323303,487.813324 634.456848,489.835114 634.208923,491.801666 
	C634.091003,492.737152 633.158630,493.569977 632.595276,494.449310 
	C632.025085,493.603271 631.064392,492.801453 630.962585,491.902405 
	C630.720459,489.764984 630.926270,487.580566 630.876038,485.416870 
	C630.784058,481.448792 628.991516,479.497009 624.315186,480.424316 
	C623.560852,479.891083 623.322449,479.403931 623.083984,478.916779 
z"
          />
          <path
            fill="#404040"
            opacity="1.000000"
            stroke="none"
            d="
M210.875092,492.956390 
	C207.504486,495.549377 204.124969,495.393707 201.093872,492.562073 
	C197.857712,489.538910 197.478577,483.977966 200.366425,480.258759 
	C203.068909,476.778290 206.628143,476.327209 210.718689,479.190765 
	C210.916275,480.394531 210.869812,480.935699 210.476196,481.338379 
	C205.323441,479.346191 202.319992,480.897919 202.083572,485.356598 
	C201.815750,490.407593 203.345810,491.707520 209.739777,491.779175 
	C210.264679,492.241333 210.569885,492.598846 210.875092,492.956390 
z"
          />
          <path
            fill="#505050"
            opacity="1.000000"
            stroke="none"
            d="
M622.767578,478.757141 
	C623.322449,479.403931 623.560852,479.891083 623.866638,480.573181 
	C623.374573,483.255035 622.689026,485.722229 622.295227,488.235138 
	C621.975586,490.275299 622.006104,492.370361 621.786011,496.021698 
	C620.113708,494.092987 618.978333,493.393127 618.942627,492.641144 
	C618.745544,488.486420 618.703857,484.315399 618.835999,480.157166 
	C618.921143,477.477020 620.341736,477.027283 622.767578,478.757141 
z"
          />
          <path
            fill="#505050"
            opacity="1.000000"
            stroke="none"
            d="
M567.341736,493.342773 
	C567.199097,487.906982 567.199097,482.811920 567.199097,477.716858 
	C567.720764,477.655457 568.242432,477.594055 568.764099,477.532654 
	C569.303223,478.211456 570.283203,478.874023 570.308960,479.571777 
	C570.460999,483.691956 570.515259,487.827789 570.265991,491.937988 
	C570.227417,492.573730 568.454956,493.104309 567.341736,493.342773 
z"
          />
          <path
            fill="#505050"
            opacity="1.000000"
            stroke="none"
            d="
M210.823349,481.476898 
	C210.869812,480.935699 210.916275,480.394531 210.979095,479.446045 
	C212.330795,478.633545 213.666138,478.228302 215.845291,477.567017 
	C215.845291,482.940155 216.132446,487.685913 215.755386,492.378265 
	C215.448013,496.203308 213.273621,494.120941 211.263184,493.055847 
	C210.569885,492.598846 210.264679,492.241333 209.990234,491.499268 
	C213.036591,488.130920 212.636826,484.862762 210.823349,481.476898 
z"
          />
          <path
            fill="#41DF4C"
            opacity="1.000000"
            stroke="none"
            d="
M419.641998,335.058411 
	C419.757294,335.808777 419.552887,336.648865 419.179047,338.154846 
	C415.022369,346.520782 411.035156,354.220856 406.788879,362.099854 
	C406.043091,363.378357 405.556335,364.477966 405.069580,365.577576 
	C405.069611,365.577576 404.933380,365.857422 404.923920,366.012817 
	C404.639801,365.299835 403.867218,364.171509 404.164642,363.601807 
	C409.137939,354.075867 414.245026,344.619781 419.641998,335.058411 
z"
          />
          <path
            fill="#404040"
            opacity="1.000000"
            stroke="none"
            d="
M157.907425,478.266418 
	C158.413712,479.601898 158.672867,480.654510 159.100433,482.086975 
	C158.942596,486.448853 158.616318,490.430878 158.224976,495.207214 
	C156.833145,494.186066 155.755020,493.773987 155.739563,493.325256 
	C155.580780,488.720551 155.526718,484.108063 155.652573,479.503479 
	C155.666901,478.978943 156.960083,478.489349 157.907425,478.266418 
z"
          />
          <path
            fill="#3CAE3F"
            opacity="1.000000"
            stroke="none"
            d="
M400.643280,189.239502 
	C399.940643,189.219315 399.276062,188.802200 398.371216,188.168167 
	C396.185394,183.507507 394.239838,179.063766 392.122040,174.314026 
	C390.532074,170.455276 389.114380,166.902512 387.711365,162.997055 
	C392.044495,171.377106 396.362946,180.109848 400.643280,189.239502 
z"
          />
          <path
            fill="#E9E9E9"
            opacity="1.000000"
            stroke="none"
            d="
M293.549286,490.935242 
	C293.130096,490.218262 293.048828,489.409546 292.989807,488.822388 
	C289.961823,489.739441 286.950165,491.029480 283.832703,491.380951 
	C282.807709,491.496521 281.508698,489.181488 279.833557,487.463715 
	C284.977905,487.055573 289.112976,486.727509 293.624023,486.199707 
	C293.962402,487.614532 293.924805,489.229034 293.549286,490.935242 
z"
          />
          <path
            fill="#41DF4C"
            opacity="1.000000"
            stroke="none"
            d="
M401.414032,241.330841 
	C404.632019,246.783447 407.785461,252.580261 410.933197,258.748047 
	C407.734833,253.304352 404.542145,247.489700 401.414032,241.330841 
z"
          />
          <path
            fill="#161616"
            opacity="1.000000"
            stroke="none"
            d="
M178.241425,481.240112 
	C178.481522,480.328735 178.937927,479.732819 179.709351,479.062256 
	C184.314102,482.731812 181.604599,487.709503 181.871643,492.542480 
	C180.936737,493.003723 180.461960,493.004028 179.987198,493.004333 
	C179.477371,489.188110 178.967545,485.371826 178.241425,481.240112 
z"
          />
          <path
            fill="#3CAE3F"
            opacity="1.000000"
            stroke="none"
            d="
M404.037292,201.975418 
	C403.222870,199.813080 402.408447,197.650726 401.332275,195.247528 
	C401.016724,194.261719 400.962860,193.516769 401.171509,192.488831 
	C401.885376,192.483658 402.336670,192.761459 402.787964,193.039276 
	C402.787964,193.039276 403.032410,193.420471 403.085480,193.640732 
	C404.385315,197.030365 405.632050,200.199738 406.586853,203.667068 
	C405.542358,203.301819 404.789856,202.638611 404.037292,201.975418 
z"
          />
          <path
            fill="#3CAE3F"
            opacity="1.000000"
            stroke="none"
            d="
M379.729980,148.743973 
	C376.804382,148.392456 373.760010,147.738876 370.306030,146.988617 
	C373.599670,144.494431 376.624451,146.349060 379.729980,148.743973 
z"
          />
          <path
            fill="#3CAE3F"
            opacity="1.000000"
            stroke="none"
            d="
M387.516663,161.832413 
	C386.003998,159.598648 384.527740,157.013351 383.150085,154.061157 
	C384.683502,156.289795 386.118286,158.885345 387.516663,161.832413 
z"
          />
          <path
            fill="#D3D3D3"
            opacity="1.000000"
            stroke="none"
            d="
M483.463135,481.021240 
	C483.538147,483.361572 483.267914,485.782532 482.943787,488.619751 
	C479.002228,486.277435 481.921051,483.714417 483.463135,481.021240 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M385.785400,205.843903 
	C386.318909,207.179214 386.634705,208.370026 386.883118,209.926758 
	C385.744263,208.372757 384.672791,206.452835 383.709045,204.215546 
	C384.400421,204.498596 384.984100,205.098999 385.785400,205.843903 
z"
          />
          <path
            fill="#0D9F0D"
            opacity="1.000000"
            stroke="none"
            d="
M394.065674,224.354858 
	C394.954590,224.489288 395.825256,224.963303 396.829437,226.052948 
	C396.997620,227.331345 397.032318,227.994125 397.066986,228.656906 
	C397.066986,228.656906 396.974640,228.694366 396.991577,228.738953 
	C396.021515,227.420517 395.034454,226.057495 394.065674,224.354858 
z"
          />
          <path
            fill="#41DF4C"
            opacity="1.000000"
            stroke="none"
            d="
M411.335388,260.330444 
	C412.570557,261.764099 413.765320,263.534180 414.971954,265.654297 
	C413.754181,264.225189 412.524567,262.446045 411.335388,260.330444 
z"
          />
          <path
            fill="#464646"
            opacity="1.000000"
            stroke="none"
            d="
M465.243317,494.137024 
	C465.001526,491.020996 465.001526,488.059174 465.001526,485.097382 
	C465.335907,485.089996 465.670288,485.082642 466.004669,485.075287 
	C466.004669,487.764801 466.004669,490.454315 466.004456,493.595581 
	C465.831207,494.128632 465.658173,494.209930 465.243317,494.137024 
z"
          />
          <path
            fill="#37C343"
            opacity="1.000000"
            stroke="none"
            d="
M326.752625,366.416168 
	C328.158447,366.139374 329.808105,366.077545 331.950317,366.098267 
	C330.627411,366.330963 328.811951,366.481049 326.752625,366.416168 
z"
          />
          <path
            fill="#41DF4C"
            opacity="1.000000"
            stroke="none"
            d="
M398.292725,233.313721 
	C398.914246,233.424042 399.467682,233.878235 400.105377,234.614502 
	C400.420502,235.873566 400.651398,236.850571 400.811157,238.207169 
	C399.901550,236.943695 399.063080,235.300629 398.292725,233.313721 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M388.442230,213.345825 
	C389.285431,214.483139 390.178955,215.944717 391.009338,217.745651 
	C390.128296,216.613373 389.310425,215.141754 388.442230,213.345825 
z"
          />
          <path
            fill="#D3D3D3"
            opacity="1.000000"
            stroke="none"
            d="
M487.017639,494.374695 
	C487.819916,494.188080 488.665985,494.324615 489.768738,494.654968 
	C489.037415,494.798523 488.049438,494.748199 487.017639,494.374695 
z"
          />
          <path
            fill="#464646"
            opacity="1.000000"
            stroke="none"
            d="
M179.907593,493.281860 
	C180.461960,493.004028 180.936737,493.003723 181.767426,492.992676 
	C181.964920,493.466248 181.806488,493.950562 181.648041,494.434875 
	C181.041351,494.143036 180.434677,493.851227 179.907593,493.281860 
z"
          />
          <path
            fill="#37C343"
            opacity="1.000000"
            stroke="none"
            d="
M306.723999,366.391968 
	C307.506592,366.139160 308.491333,366.080414 309.965881,366.105255 
	C309.279205,366.321228 308.102661,366.453613 306.723999,366.391968 
z"
          />
          <path
            fill="#464646"
            opacity="1.000000"
            stroke="none"
            d="
M457.482910,480.532562 
	C458.053070,480.177887 458.983795,479.740356 459.955139,479.626221 
	C459.278320,480.116302 458.560913,480.282990 457.482910,480.532562 
z"
          />
          <path
            fill="#3CAE3F"
            opacity="1.000000"
            stroke="none"
            d="
M362.770020,152.250732 
	C362.756104,153.025787 362.449829,153.859055 361.915802,154.848160 
	C361.951263,154.105637 362.214478,153.207275 362.770020,152.250732 
z"
          />
          <path
            fill="#71E577"
            opacity="1.000000"
            stroke="none"
            d="
M402.851257,192.700974 
	C402.336670,192.761459 401.885376,192.483658 401.180298,192.115692 
	C400.881042,191.565338 400.835602,191.105118 400.915955,190.321442 
	C401.666016,190.786194 402.290253,191.574432 402.851257,192.700974 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M381.624634,200.207138 
	C382.148163,200.494766 382.581177,201.053268 382.931335,201.893463 
	C382.410370,201.609436 381.972260,201.043716 381.624634,200.207138 
z"
          />
          <path
            fill="#D3D3D3"
            opacity="1.000000"
            stroke="none"
            d="
M493.095703,495.075653 
	C493.482788,494.621277 493.997223,494.414703 494.796692,494.244080 
	C494.462189,494.627808 493.842621,494.975647 493.095703,495.075653 
z"
          />
          <path
            fill="#D3D3D3"
            opacity="1.000000"
            stroke="none"
            d="
M483.401978,490.765259 
	C484.039459,491.020782 484.555786,491.643707 485.147034,492.564941 
	C484.574951,492.286407 483.927917,491.709564 483.401978,490.765259 
z"
          />
          <path
            fill="#71E577"
            opacity="1.000000"
            stroke="none"
            d="
M468.815674,276.325623 
	C469.079163,275.973145 469.367737,275.931000 469.867676,275.864655 
	C469.903076,276.496460 469.726807,277.150818 469.242828,277.844055 
	C468.903778,277.467834 468.872406,277.052704 468.815674,276.325623 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M371.385559,184.585739 
	C371.959290,184.514969 372.910004,184.367722 373.946106,184.494873 
	C373.275177,184.682617 372.518890,184.595947 371.385559,184.585739 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M374.611694,186.206406 
	C375.136932,186.477875 375.570160,187.013885 375.930115,187.825012 
	C375.411102,187.557068 374.965363,187.014008 374.611694,186.206406 
z"
          />
          <path
            fill="#3CAE3F"
            opacity="1.000000"
            stroke="none"
            d="
M382.478271,151.833038 
	C381.909546,151.615204 381.424469,151.117874 380.974609,150.337799 
	C381.527222,150.554550 382.044586,151.054031 382.478271,151.833038 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M380.006104,197.395294 
	C379.937592,197.432785 379.942719,197.277832 379.944519,197.236755 
	C379.946320,197.195679 380.074615,197.357819 380.006104,197.395294 
z"
          />
          <path
            fill="#36D441"
            opacity="1.000000"
            stroke="none"
            d="
M470.119812,331.922791 
	C471.792389,335.320435 473.464935,338.718109 474.828491,342.565247 
	C474.274170,343.747833 473.910522,344.465424 473.811951,345.217712 
	C473.670715,346.295135 473.723175,347.397919 473.692932,348.489899 
	C472.637238,347.930695 471.088898,347.632721 470.625732,346.759308 
	C469.576935,344.781647 469.063171,342.520264 468.618896,341.216553 
	C463.949951,340.485840 459.791656,339.835052 455.512665,339.165375 
	C455.089722,340.070038 454.373322,341.602417 453.397217,342.908752 
	C451.183411,338.518768 449.229248,334.354797 447.275085,330.190857 
	C451.627716,327.988922 455.980347,325.786957 460.546936,323.476776 
	C460.546936,324.754608 460.546936,326.113525 460.546936,328.226318 
	C462.491394,327.526337 464.093811,326.949493 465.909851,326.059937 
	C466.420654,326.266479 466.717926,326.785736 467.044250,327.970764 
	C468.088806,329.731934 469.104309,330.827362 470.119812,331.922791 
z"
          />
          <path
            fill="#3EE949"
            opacity="1.000000"
            stroke="none"
            d="
M432.994843,240.911133 
	C435.385071,240.606033 437.775269,240.300949 440.585449,240.005066 
	C440.631195,244.700439 441.198669,249.107773 444.269318,252.975510 
	C444.891968,253.759781 444.783051,255.124878 445.009979,256.614136 
	C441.942596,257.304413 438.876678,257.602722 435.405975,257.954285 
	C435.001221,258.007568 434.998962,258.000031 434.992981,257.623901 
	C433.852142,255.771576 432.854004,253.176865 431.558075,253.018448 
	C426.705017,252.425262 425.957184,248.828583 424.910889,245.321442 
	C424.675171,244.531479 424.951599,243.588760 424.992279,242.716354 
	C425.986023,242.832520 427.198151,242.623093 427.937317,243.118851 
	C432.074585,245.893753 432.440430,245.778275 432.994843,240.911133 
z"
          />
          <path
            fill="#D4F8D5"
            opacity="1.000000"
            stroke="none"
            d="
M447.051727,330.023560 
	C449.229248,334.354797 451.183411,338.518768 453.358032,343.212280 
	C453.047058,344.522827 452.515656,345.303802 451.529663,345.976318 
	C446.117981,345.740143 441.160858,345.612427 436.230103,345.145386 
	C436.314941,343.903168 436.373383,343.000275 436.431824,342.097351 
	C438.725159,337.705261 441.018494,333.313202 443.401398,328.564575 
	C444.603424,328.757416 445.715881,329.306854 447.051727,330.023560 
z"
          />
          <path
            fill="#D4F8D5"
            opacity="1.000000"
            stroke="none"
            d="
M452.001160,260.940491 
	C452.265839,261.439667 452.530457,261.938843 452.879211,262.715759 
	C452.963348,262.993530 452.743652,263.283478 452.398621,263.611359 
	C450.438171,267.325928 448.822784,270.712616 447.207397,274.099274 
	C447.207397,274.099274 447.014526,274.466125 446.570557,274.707214 
	C445.088440,274.958344 444.050323,274.968414 443.012177,274.978485 
	C441.442566,271.858276 439.872955,268.738068 438.143677,264.898987 
	C439.015320,263.095703 439.956604,261.203339 441.094666,261.076477 
	C444.690552,260.675690 448.360107,260.935944 452.001160,260.940491 
z"
          />
          <path
            fill="#71E577"
            opacity="1.000000"
            stroke="none"
            d="
M451.926270,260.564026 
	C448.360107,260.935944 444.690552,260.675690 441.094666,261.076477 
	C439.956604,261.203339 439.015320,263.095703 437.949097,264.610779 
	C434.784576,262.945007 435.060822,261.019531 438.461548,259.795593 
	C440.225555,259.160706 442.250092,259.249786 444.585815,259.004089 
	C447.293549,259.393402 449.572449,259.790466 451.926270,260.564026 
z"
          />
          <path
            fill="#71E577"
            opacity="1.000000"
            stroke="none"
            d="
M443.078735,275.284058 
	C444.050323,274.968414 445.088440,274.958344 446.514404,274.906677 
	C446.501129,275.866180 446.100006,276.867279 445.350861,277.941803 
	C444.383636,277.206696 443.764465,276.398163 443.078735,275.284058 
z"
          />
          <path
            fill="#F6F6F6"
            opacity="1.000000"
            stroke="none"
            d="
M535.861633,430.428284 
	C535.629822,433.989838 535.879150,437.246490 535.070618,440.214935 
	C532.833313,448.428986 524.864136,453.605286 516.923157,452.464203 
	C508.126404,451.200134 502.346405,444.721649 501.841370,435.962952 
	C501.386688,428.077301 502.465240,420.800812 510.091187,416.634369 
	C521.785950,410.244965 533.546448,416.470520 535.861633,430.428284 
z"
          />
          <path
            fill="#E9E9E9"
            opacity="1.000000"
            stroke="none"
            d="
M570.746582,432.666504 
	C563.164795,432.669159 563.164795,432.669159 563.169189,425.360687 
	C563.169495,424.863068 563.115845,424.358521 563.178528,423.868927 
	C563.580750,420.728394 563.764832,414.945740 564.467163,414.881073 
	C569.110168,414.453522 574.072571,414.555389 578.523010,415.868011 
	C582.357910,416.999115 583.436096,421.068512 582.799866,425.000549 
	C582.192627,428.752625 580.029968,431.348480 576.117432,432.078613 
	C574.492859,432.381805 572.829163,432.475677 570.746582,432.666504 
z"
          />
          <path
            fill="#F6F6F6"
            opacity="1.000000"
            stroke="none"
            d="
M134.706909,424.942810 
	C134.706985,421.317200 134.706985,418.175018 134.706985,414.433075 
	C141.118454,414.477997 147.944489,412.203949 152.429520,417.912476 
	C154.185013,420.146881 154.032715,424.983307 152.999100,428.043549 
	C151.503204,432.472412 145.339203,433.489899 134.706833,432.074371 
	C134.706833,429.929932 134.706833,427.678070 134.706909,424.942810 
z"
          />
          <path
            fill="#E9E9E9"
            opacity="1.000000"
            stroke="none"
            d="
M195.224213,427.776855 
	C196.856583,432.319305 198.393341,436.484955 200.109161,441.135986 
	C194.422623,441.135986 189.355667,441.135986 183.259933,441.135986 
	C185.901413,433.396240 188.416016,426.028198 191.444611,417.154175 
	C193.007401,421.500610 194.067993,424.450348 195.224213,427.776855 
z"
          />
          <path
            fill="#0D9F0D"
            opacity="1.000000"
            stroke="none"
            d="
M348.933075,184.016571 
	C351.434570,178.493790 353.936066,172.971008 356.708771,167.234680 
	C362.867737,168.198303 367.569580,174.175049 367.035675,179.762238 
	C366.669800,183.590790 366.350098,187.423737 366.002136,191.647949 
	C365.190277,193.585220 364.386078,195.129288 363.062378,196.642181 
	C361.934845,191.973740 361.618744,187.270477 360.591522,182.728027 
	C360.102966,180.567642 358.224609,179.240494 355.552704,180.886765 
	C353.487396,182.159317 351.149689,182.989639 348.933075,184.016571 
z"
          />
          <path
            fill="#71E577"
            opacity="1.000000"
            stroke="none"
            d="
M447.543274,274.040039 
	C448.822784,270.712616 450.438171,267.325928 452.359070,263.788879 
	C452.729248,267.854004 451.693665,271.560364 447.543274,274.040039 
z"
          />
          <path
            fill="#41DF4C"
            opacity="1.000000"
            stroke="none"
            d="
M464.641968,282.038666 
	C464.542999,283.273010 464.122620,284.556213 463.359070,285.925049 
	C463.450775,284.703003 463.885651,283.395264 464.641968,282.038666 
z"
          />
          <path
            fill="#41DF4C"
            opacity="1.000000"
            stroke="none"
            d="
M461.602081,289.033020 
	C461.466125,290.207062 461.014771,291.436981 460.264954,292.832214 
	C460.406525,291.694672 460.846588,290.391815 461.602081,289.033020 
z"
          />
          <path
            fill="#41DF4C"
            opacity="1.000000"
            stroke="none"
            d="
M432.610870,240.864288 
	C432.440430,245.778275 432.074585,245.893753 427.937317,243.118851 
	C427.198151,242.623093 425.986023,242.832520 424.992279,242.716354 
	C424.951599,243.588760 424.675171,244.531479 424.910889,245.321442 
	C425.957184,248.828583 426.705017,252.425262 431.558075,253.018448 
	C432.854004,253.176865 433.852142,255.771576 434.989197,257.623291 
	C434.328705,257.999115 433.665955,258.000061 433.003204,257.999786 
	C425.145569,257.996552 423.992340,256.943390 422.967804,249.244568 
	C422.781952,247.848160 422.505341,245.990356 421.561798,245.254486 
	C418.372986,242.767685 419.120941,240.311584 421.032166,237.245148 
	C424.807678,238.191010 428.517303,239.504227 432.610870,240.864288 
z"
          />
          <path
            fill="#03AC07"
            opacity="1.000000"
            stroke="none"
            d="
M321.031464,333.937134 
	C324.297424,340.199219 327.582550,346.451477 330.812134,352.732269 
	C331.413635,353.902039 331.654205,355.264160 332.296753,356.404938 
	C335.673615,362.400848 334.742432,363.984558 327.241333,364.016815 
	C308.469666,364.097595 289.697388,364.032684 270.471863,363.591187 
	C270.345947,360.711700 271.126343,358.227783 270.917480,355.830048 
	C270.254700,348.221527 273.493225,341.685913 276.195099,335.001465 
	C276.679016,333.804199 277.970642,332.933350 278.912354,331.886261 
	C285.816681,338.449249 286.803772,346.946564 288.590790,354.924744 
	C294.296051,354.187469 291.966827,350.613190 292.468933,347.992157 
	C298.351471,347.992859 303.767548,347.992859 309.598114,347.992859 
	C309.667908,348.431030 309.683868,349.583649 310.055542,350.607117 
	C310.555420,351.983582 311.329773,353.260406 311.986267,354.580017 
	C312.652069,353.267670 313.624969,352.021973 313.926941,350.630676 
	C314.895691,346.167206 315.313110,341.559753 316.607635,337.205383 
	C317.024994,335.801636 319.498566,335.009216 321.031464,333.937134 
z"
          />
          <path
            fill="#15B821"
            opacity="1.000000"
            stroke="none"
            d="
M320.982391,333.575562 
	C319.498566,335.009216 317.024994,335.801636 316.607635,337.205383 
	C315.313110,341.559753 314.895691,346.167206 313.926941,350.630676 
	C313.624969,352.021973 312.652069,353.267670 311.986267,354.580017 
	C311.329773,353.260406 310.555420,351.983582 310.055542,350.607117 
	C309.683868,349.583649 309.667908,348.431030 309.598114,347.992859 
	C303.767548,347.992859 298.351471,347.992859 292.489136,347.593079 
	C292.135193,346.669464 292.227570,346.145569 292.755188,345.570984 
	C297.720428,345.520264 302.250366,345.520264 308.218842,345.520264 
	C305.119171,339.501831 302.646606,334.701019 300.174011,329.900177 
	C299.557068,329.882080 298.940125,329.863953 298.323181,329.845856 
	C296.330414,335.111176 294.337646,340.376526 291.974548,345.710144 
	C291.330414,343.402191 291.056671,341.026031 290.782898,338.649841 
	C288.824677,336.724609 287.308014,334.298157 286.015411,331.757751 
	C284.356567,328.497559 283.939178,324.189575 278.957336,323.963013 
	C280.771362,320.406586 282.585388,316.850189 284.745758,313.524841 
	C286.136841,318.355225 287.181519,322.954559 288.295837,327.860504 
	C289.742737,326.670197 291.364166,325.336334 292.995178,324.380890 
	C293.561066,326.192047 294.117340,327.624725 294.316650,328.138123 
	C297.545624,328.138123 300.114624,327.434448 301.980072,328.281036 
	C306.175293,330.184937 306.314850,329.945862 307.121521,325.350159 
	C308.566254,317.119720 304.576050,309.308533 306.476929,300.833435 
	C308.090729,293.638092 307.043518,285.819611 306.909821,278.281525 
	C306.884094,276.832123 305.633636,275.404388 304.747620,273.680786 
	C304.138092,273.175995 303.729095,272.957123 303.371521,272.419067 
	C304.070465,271.000824 304.717987,269.901794 305.757965,268.701050 
	C306.343903,267.860718 306.537415,267.122131 306.703949,266.283020 
	C306.676971,266.182495 306.814667,266.026428 307.110413,265.844238 
	C307.518890,265.226349 307.631622,264.790680 307.664978,264.115204 
	C307.734558,263.582336 307.883453,263.289246 308.409912,263.006287 
	C310.085327,262.052429 311.404846,261.113647 312.628632,260.063385 
	C312.704315,259.998413 312.026520,259.055511 311.699310,258.521088 
	C310.447968,259.363525 309.196655,260.205963 307.685120,260.966064 
	C307.301056,260.750458 307.177155,260.617218 307.045532,260.199463 
	C306.696442,259.282867 306.355103,258.650787 306.049622,257.640900 
	C309.557220,251.709305 305.952026,245.795639 306.957550,240.116455 
	C307.404785,240.090775 307.851990,240.065094 308.691284,240.412231 
	C310.093994,243.491379 311.104614,246.197739 312.115265,248.904083 
	C312.594055,248.765945 313.072876,248.627808 313.551666,248.489670 
	C313.710693,245.674393 313.869720,242.859100 314.028748,240.043823 
	C315.410309,240.032990 316.791870,240.022171 318.587830,240.473953 
	C318.149902,245.398010 316.812775,249.834641 316.582825,254.327942 
	C316.357086,258.738373 317.283081,263.204254 317.650360,267.650177 
	C317.813110,269.619781 318.382507,271.773071 317.815552,273.537109 
	C316.853668,276.530029 315.902069,279.031616 320.052979,280.076477 
	C318.790955,284.006317 317.528931,287.936157 316.266907,291.866028 
	C315.765228,291.757996 315.263550,291.649994 314.761841,291.541992 
	C314.110474,289.450012 313.459137,287.358032 312.807770,285.266052 
	C312.261719,285.322235 311.715668,285.378418 311.169586,285.434631 
	C310.781464,290.782318 310.379883,296.129059 310.009094,301.477966 
	C309.657654,306.547699 309.605774,311.577026 313.370148,316.278076 
	C317.333191,315.853851 317.306152,319.609070 318.048523,322.959656 
	C318.815460,326.420898 319.956146,329.799316 320.982391,333.575562 
M292.325317,331.775696 
	C292.583984,332.380371 292.842682,332.985046 293.101349,333.589722 
	C293.599365,333.096527 294.097382,332.603333 294.595398,332.110168 
	C293.994629,331.844635 293.393829,331.579071 292.325317,331.775696 
M312.707123,267.560089 
	C312.988800,267.291534 313.270477,267.022980 313.552124,266.754395 
	C313.440735,266.637878 313.238708,266.413879 313.231079,266.420319 
	C312.934021,266.670929 312.654694,266.942596 312.707123,267.560089 
z"
          />
          <path
            fill="#06B210"
            opacity="1.000000"
            stroke="none"
            d="
M320.310364,279.836548 
	C315.902069,279.031616 316.853668,276.530029 317.815552,273.537109 
	C318.382507,271.773071 317.813110,269.619781 317.650360,267.650177 
	C317.283081,263.204254 316.357086,258.738373 316.582825,254.327942 
	C316.812775,249.834641 318.149902,245.398010 319.002136,240.487000 
	C320.656677,238.310059 322.768066,236.834396 323.883667,234.809982 
	C327.838806,227.632812 331.453949,220.268280 335.586853,213.294922 
	C336.087585,218.880417 339.996338,220.369492 344.839874,222.278748 
	C344.355560,216.018234 352.882080,213.828033 350.436249,206.860413 
	C350.316193,206.518478 351.322449,205.442215 351.975128,205.227615 
	C354.580414,204.371017 357.253326,203.720139 359.901215,202.993134 
	C354.893890,212.677597 349.905182,222.371780 344.851440,232.031952 
	C344.328613,233.031342 343.419037,233.828415 342.078827,234.658478 
	C340.985657,233.390274 340.051208,232.164856 340.098755,230.978821 
	C340.290131,226.204636 337.665009,224.715561 332.193115,224.366470 
	C331.786560,232.207474 331.253723,239.745407 331.147644,247.289383 
	C331.130432,248.513367 333.005615,249.763977 334.006104,251.002289 
	C331.079529,257.423218 328.188232,263.860596 325.211304,270.258118 
	C323.745148,273.408966 322.120636,276.486145 320.310364,279.836548 
z"
          />
          <path
            fill="#03AC07"
            opacity="1.000000"
            stroke="none"
            d="
M360.070251,202.696930 
	C357.253326,203.720139 354.580414,204.371017 351.975128,205.227615 
	C351.322449,205.442215 350.316193,206.518478 350.436249,206.860413 
	C352.882080,213.828033 344.355560,216.018234 344.839874,222.278748 
	C339.996338,220.369492 336.087585,218.880417 335.935425,213.203491 
	C340.139740,203.405792 344.384369,194.019730 348.781036,184.325119 
	C351.149689,182.989639 353.487396,182.159317 355.552704,180.886765 
	C358.224609,179.240494 360.102966,180.567642 360.591522,182.728027 
	C361.618744,187.270477 361.934845,191.973740 362.811310,196.849548 
	C362.132965,198.858994 361.186157,200.629868 360.070251,202.696930 
z"
          />
          <path
            fill="#06B210"
            opacity="1.000000"
            stroke="none"
            d="
M292.319916,345.621704 
	C292.227570,346.145569 292.135193,346.669464 292.022644,347.592407 
	C291.966827,350.613190 294.296051,354.187469 288.590790,354.924744 
	C286.803772,346.946564 285.816681,338.449249 278.912354,331.886261 
	C277.970642,332.933350 276.679016,333.804199 276.195099,335.001465 
	C273.493225,341.685913 270.254700,348.221527 270.917480,355.830048 
	C271.126343,358.227783 270.345947,360.711700 270.002441,363.578674 
	C269.553986,363.997009 269.121368,363.992798 268.365295,363.968628 
	C267.102448,362.829376 266.163116,361.710144 265.088196,359.868073 
	C264.959595,356.760162 264.966583,354.375061 264.973541,351.989990 
	C269.457642,342.801575 273.941772,333.613159 278.691650,324.193909 
	C283.939178,324.189575 284.356567,328.497559 286.015411,331.757751 
	C287.308014,334.298157 288.824677,336.724609 290.782898,338.649841 
	C291.056671,341.026031 291.330414,343.402191 291.818970,345.867615 
	C292.033783,345.956848 292.319916,345.621704 292.319916,345.621704 
z"
          />
          <path
            fill="#04B715"
            opacity="1.000000"
            stroke="none"
            d="
M334.162903,250.655823 
	C333.005615,249.763977 331.130432,248.513367 331.147644,247.289383 
	C331.253723,239.745407 331.786560,232.207474 332.193115,224.366470 
	C337.665009,224.715561 340.290131,226.204636 340.098755,230.978821 
	C340.051208,232.164856 340.985657,233.390274 341.770691,234.827393 
	C340.800659,237.882004 339.621185,240.753448 338.228088,243.517227 
	C337.054169,245.846222 335.631989,248.050110 334.162903,250.655823 
z"
          />
          <path
            fill="#13C024"
            opacity="1.000000"
            stroke="none"
            d="
M292.985596,324.002441 
	C291.364166,325.336334 289.742737,326.670197 288.295837,327.860504 
	C287.181519,322.954559 286.136841,318.355225 285.050323,313.364624 
	C291.091675,306.344666 291.234802,304.887299 285.056122,294.404541 
	C281.193085,287.850586 278.398254,280.672821 274.984802,273.844635 
	C273.908661,271.692017 271.453217,269.860077 271.233948,267.717163 
	C270.579132,261.317719 267.547638,254.942810 270.985687,248.294250 
	C272.342865,247.445206 273.128326,246.694305 273.913788,245.943405 
	C273.917847,245.523422 273.921906,245.103424 273.976349,244.021729 
	C274.366577,242.262543 274.706390,241.165070 275.046204,240.067596 
	C277.716339,240.063934 280.386444,240.060257 283.553741,240.407837 
	C284.396362,241.855179 284.741791,242.951263 285.079803,244.365433 
	C285.074677,245.107712 285.076996,245.531921 285.053833,246.351517 
	C285.902008,250.682419 286.754333,254.623093 287.702271,258.540619 
	C287.759033,258.775085 288.580170,258.824615 289.038757,259.369202 
	C289.991150,264.112091 288.773499,269.073242 293.463409,272.327301 
	C294.677155,273.169434 294.558197,275.932343 295.010315,278.171997 
	C293.684998,280.702911 292.590088,282.770386 294.936584,285.092926 
	C295.615356,285.764740 295.764526,287.227570 295.735535,288.316589 
	C295.557190,295.015472 295.436432,301.724304 294.942261,308.403656 
	C294.556122,313.622925 293.656860,318.804260 292.985596,324.002441 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M274.636047,240.044464 
	C274.706390,241.165070 274.366577,242.262543 273.970001,243.706757 
	C272.632812,242.974228 271.352386,241.894943 270.122314,240.470490 
	C271.523712,240.090683 272.874786,240.056015 274.636047,240.044464 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M264.653259,352.182800 
	C264.966583,354.375061 264.959595,356.760162 264.932251,359.593628 
	C261.443573,357.733887 262.766663,355.063934 264.653259,352.182800 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M273.571930,245.958984 
	C273.128326,246.694305 272.342865,247.445206 271.255096,248.043427 
	C271.711853,247.252014 272.470947,246.613281 273.571930,245.958984 
z"
          />
          <path
            fill="#21CB32"
            opacity="1.000000"
            stroke="none"
            d="
M436.026245,342.081268 
	C436.373383,343.000275 436.314941,343.903168 436.210968,345.590881 
	C439.569794,348.876526 448.681946,348.708954 451.984253,346.084778 
	C452.515656,345.303802 453.047058,344.522827 453.617676,343.438293 
	C454.373322,341.602417 455.089722,340.070038 455.512665,339.165375 
	C459.791656,339.835052 463.949951,340.485840 468.618896,341.216553 
	C469.063171,342.520264 469.576935,344.781647 470.625732,346.759308 
	C471.088898,347.632721 472.637238,347.930695 473.692932,348.489899 
	C473.723175,347.397919 473.670715,346.295135 473.811951,345.217712 
	C473.910522,344.465424 474.274170,343.747833 474.854736,342.934998 
	C478.199677,348.355652 482.789490,353.364746 480.007019,360.769165 
	C476.981018,362.048798 474.620911,363.837616 472.240784,363.864655 
	C452.082062,364.093475 431.919403,364.047424 411.759003,363.914856 
	C410.184387,363.904510 408.618134,362.615570 407.047974,361.920898 
	C411.035156,354.220856 415.022369,346.520782 419.117859,338.477509 
	C420.321564,338.323334 422.267487,338.290710 422.383240,338.734955 
	C423.837341,344.315857 426.863251,341.108917 429.435638,341.083466 
	C431.497284,341.410706 433.558960,341.737946 436.026245,342.081268 
z"
          />
          <path
            fill="#37C343"
            opacity="1.000000"
            stroke="none"
            d="
M406.788879,362.099854 
	C408.618134,362.615570 410.184387,363.904510 411.759003,363.914856 
	C431.919403,364.047424 452.082062,364.093475 472.240784,363.864655 
	C474.620911,363.837616 476.981018,362.048798 479.735046,361.065552 
	C479.107513,364.614197 476.680695,365.918457 473.074371,365.904327 
	C450.707947,365.816559 428.341156,365.819397 405.522034,365.684875 
	C405.556335,364.477966 406.043091,363.378357 406.788879,362.099854 
z"
          />
          <path
            fill="#41DF4C"
            opacity="1.000000"
            stroke="none"
            d="
M470.058472,331.584717 
	C469.104309,330.827362 468.088806,329.731934 467.062378,328.277008 
	C468.033417,329.027222 469.015289,330.136932 470.058472,331.584717 
z"
          />
          <path
            fill="#04B715"
            opacity="1.000000"
            stroke="none"
            d="
M306.505615,240.082825 
	C305.952026,245.795639 309.557220,251.709305 305.593933,257.640320 
	C302.066193,258.010071 299.030060,258.002594 295.947876,257.595642 
	C295.816864,254.671204 295.638794,252.145081 295.670532,249.621582 
	C295.710266,246.466400 295.922485,243.313370 296.060272,240.159409 
	C299.391418,240.122665 302.722565,240.085938 306.505615,240.082825 
z"
          />
          <path
            fill="#13C024"
            opacity="1.000000"
            stroke="none"
            d="
M295.685150,240.112915 
	C295.922485,243.313370 295.710266,246.466400 295.670532,249.621582 
	C295.638794,252.145081 295.816864,254.671204 295.589111,257.561890 
	C290.728180,255.757660 290.934814,251.981003 291.917358,247.534363 
	C292.303864,244.758255 292.676758,242.394547 293.049622,240.030823 
	C293.803101,240.042694 294.556549,240.054565 295.685150,240.112915 
z"
          />
          <path
            fill="#37C343"
            opacity="1.000000"
            stroke="none"
            d="
M292.613525,240.018311 
	C292.676758,242.394547 292.303864,244.758255 291.565552,247.586411 
	C288.051758,250.590408 287.221436,246.356750 285.079285,245.956116 
	C285.076996,245.531921 285.074677,245.107712 285.429169,244.286728 
	C286.178009,242.598206 286.570068,241.306473 286.962097,240.014740 
	C288.700531,240.011765 290.438965,240.008774 292.613525,240.018311 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M313.607361,240.027863 
	C313.869720,242.859100 313.710693,245.674393 313.551666,248.489670 
	C313.072876,248.627808 312.594055,248.765945 312.115265,248.904083 
	C311.104614,246.197739 310.093994,243.491379 309.027344,240.413315 
	C310.376221,240.031693 311.781067,240.021790 313.607361,240.027863 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M286.585876,240.012909 
	C286.570068,241.306473 286.178009,242.598206 285.436584,243.968643 
	C284.741791,242.951263 284.396362,241.855179 283.998413,240.420883 
	C284.700531,240.058807 285.455078,240.034943 286.585876,240.012909 
z"
          />
          <path
            fill="#13C024"
            opacity="1.000000"
            stroke="none"
            d="
M397.443634,228.764969 
	C397.032318,227.994125 396.997620,227.331345 396.969238,226.331482 
	C397.629303,225.390625 398.771576,224.848450 398.856720,224.173004 
	C399.373016,220.076553 399.544769,215.936691 400.062164,211.840439 
	C400.234467,210.476273 401.125275,209.212326 401.617889,207.876877 
	C402.291229,206.051590 402.898010,204.201752 403.785339,202.168930 
	C404.789856,202.638611 405.542358,203.301819 406.594177,204.032379 
	C407.501678,205.534668 408.109894,206.969604 408.792419,208.734558 
	C404.963959,214.450485 402.635468,220.530991 400.747772,226.852600 
	C400.483246,227.738480 398.830475,228.209808 397.443634,228.764969 
z"
          />
          <path
            fill="#E9E9E9"
            opacity="1.000000"
            stroke="none"
            d="
M391.110321,488.380219 
	C390.320770,484.762115 390.826782,481.795441 394.270447,480.695465 
	C395.685883,480.243317 398.625336,480.774902 399.023010,481.675385 
	C399.991791,483.868988 400.668549,486.768524 400.030975,488.968781 
	C398.873566,492.963074 394.659454,492.727478 391.110321,488.380219 
z"
          />
          <path
            fill="#D3D3D3"
            opacity="1.000000"
            stroke="none"
            d="
M281.194641,484.234436 
	C281.927887,480.394928 284.425842,479.413910 287.405518,480.349701 
	C288.661499,480.744080 289.461243,482.591370 290.468384,483.778229 
	C290.148346,484.187073 289.828308,484.595917 289.508270,485.004761 
	C286.776642,484.879700 284.045013,484.754639 281.194641,484.234436 
z"
          />
          <path
            fill="#E9E9E9"
            opacity="1.000000"
            stroke="none"
            d="
M596.334778,480.550781 
	C597.316895,481.233276 598.504211,481.721985 598.510559,482.225433 
	C598.545227,484.999878 598.750488,487.938416 597.868408,490.460938 
	C597.588928,491.260010 593.056885,491.823181 592.372559,490.969666 
	C590.937561,489.179871 590.089722,486.334320 590.241394,484.002960 
	C590.438599,480.970459 592.835083,479.637848 596.334778,480.550781 
z"
          />
          <path
            fill="#E9E9E9"
            opacity="1.000000"
            stroke="none"
            d="
M210.476196,481.338379 
	C212.636826,484.862762 213.036591,488.130920 209.770538,491.394684 
	C203.345810,491.707520 201.815750,490.407593 202.083572,485.356598 
	C202.319992,480.897919 205.323441,479.346191 210.476196,481.338379 
z"
          />
          <path
            fill="#36D441"
            opacity="1.000000"
            stroke="none"
            d="
M451.529663,345.976318 
	C448.681946,348.708954 439.569794,348.876526 436.184570,345.930176 
	C441.160858,345.612427 446.117981,345.740143 451.529663,345.976318 
z"
          />
          <path
            fill="#04B715"
            opacity="1.000000"
            stroke="none"
            d="
M292.995178,324.380890 
	C293.656860,318.804260 294.556122,313.622925 294.942261,308.403656 
	C295.436432,301.724304 295.557190,295.015472 295.735535,288.316589 
	C295.764526,287.227570 295.615356,285.764740 294.936584,285.092926 
	C292.590088,282.770386 293.684998,280.702911 295.347046,278.252045 
	C297.149628,278.616089 298.574402,279.259064 300.181824,280.142273 
	C301.892365,278.243866 303.420288,276.105286 304.948212,273.966675 
	C305.633636,275.404388 306.884094,276.832123 306.909821,278.281525 
	C307.043518,285.819611 308.090729,293.638092 306.476929,300.833435 
	C304.576050,309.308533 308.566254,317.119720 307.121521,325.350159 
	C306.314850,329.945862 306.175293,330.184937 301.980072,328.281036 
	C300.114624,327.434448 297.545624,328.138123 294.316650,328.138123 
	C294.117340,327.624725 293.561066,326.192047 292.995178,324.380890 
z"
          />
          <path
            fill="#D4F8D5"
            opacity="1.000000"
            stroke="none"
            d="
M305.365540,268.802734 
	C304.717987,269.901794 304.070465,271.000824 303.104553,272.581207 
	C301.882538,274.575195 300.978943,276.087830 299.759033,277.397827 
	C296.656036,271.849579 293.869354,266.503967 291.539673,261.078064 
	C297.015564,260.826538 302.034424,260.655273 307.053284,260.484009 
	C307.177155,260.617218 307.301056,260.750458 307.617065,261.290894 
	C307.883575,262.130737 307.957977,262.563446 308.032349,262.996155 
	C307.883453,263.289246 307.734558,263.582336 307.384460,264.310059 
	C307.060394,265.171906 306.937531,265.599182 306.814667,266.026428 
	C306.814667,266.026428 306.676971,266.182495 306.405884,266.493011 
	C305.878357,267.469940 305.621948,268.136322 305.365540,268.802734 
z"
          />
          <path
            fill="#D4F8D5"
            opacity="1.000000"
            stroke="none"
            d="
M292.189331,345.799377 
	C294.337646,340.376526 296.330414,335.111176 298.323181,329.845856 
	C298.940125,329.863953 299.557068,329.882080 300.174011,329.900177 
	C302.646606,334.701019 305.119171,339.501831 308.218842,345.520264 
	C302.250366,345.520264 297.720428,345.520264 292.755188,345.570984 
	C292.319916,345.621704 292.033783,345.956848 292.189331,345.799377 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M307.045532,260.199463 
	C302.034424,260.655273 297.015564,260.826538 291.525330,260.741516 
	C290.384491,259.976868 289.715057,259.468536 289.045624,258.960205 
	C288.580170,258.824615 287.759033,258.775085 287.702271,258.540619 
	C286.754333,254.623093 285.902008,250.682419 285.053833,246.351517 
	C287.221436,246.356750 288.051758,250.590408 291.551941,247.998810 
	C290.934814,251.981003 290.728180,255.757660 295.635132,257.961365 
	C299.030060,258.002594 302.066193,258.010071 305.558044,258.018127 
	C306.355103,258.650787 306.696442,259.282867 307.045532,260.199463 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M300.075317,277.600464 
	C300.978943,276.087830 301.882538,274.575195 303.053131,272.900391 
	C303.729095,272.957123 304.138092,273.175995 304.747620,273.680786 
	C303.420288,276.105286 301.892365,278.243866 300.183960,279.854187 
	C300.027405,278.750763 300.051361,278.175629 300.075317,277.600464 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M308.409943,263.006287 
	C307.957977,262.563446 307.883575,262.130737 307.877258,261.373230 
	C309.196655,260.205963 310.447968,259.363525 311.699310,258.521088 
	C312.026520,259.055511 312.704315,259.998413 312.628632,260.063385 
	C311.404846,261.113647 310.085327,262.052429 308.409943,263.006287 
z"
          />
          <path
            fill="#04B715"
            opacity="1.000000"
            stroke="none"
            d="
M292.559204,331.544617 
	C293.393829,331.579071 293.994629,331.844635 294.595398,332.110168 
	C294.097382,332.603333 293.599365,333.096527 293.101349,333.589722 
	C292.842682,332.985046 292.583984,332.380371 292.559204,331.544617 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M312.540100,267.385773 
	C312.654694,266.942596 312.934021,266.670929 313.231079,266.420319 
	C313.238708,266.413879 313.440735,266.637878 313.552124,266.754395 
	C313.270477,267.022980 312.988800,267.291534 312.540100,267.385773 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M305.757965,268.701050 
	C305.621948,268.136322 305.878357,267.469940 306.432861,266.593506 
	C306.537415,267.122131 306.343903,267.860718 305.757965,268.701050 
z"
          />
          <path
            fill="#26BA32"
            opacity="1.000000"
            stroke="none"
            d="
M307.110413,265.844238 
	C306.937531,265.599182 307.060394,265.171906 307.463806,264.549805 
	C307.631622,264.790680 307.518890,265.226349 307.110413,265.844238 
z"
          />
          <path
            fill="#37C343"
            opacity="1.000000"
            stroke="none"
            d="
M299.759033,277.397827 
	C300.051361,278.175629 300.027405,278.750763 300.001312,279.614014 
	C298.574402,279.259064 297.149628,278.616089 295.388123,277.893066 
	C294.558197,275.932343 294.677155,273.169434 293.463409,272.327301 
	C288.773499,269.073242 289.991150,264.112091 289.038757,259.369202 
	C289.715057,259.468536 290.384491,259.976868 291.068298,260.821777 
	C293.869354,266.503967 296.656036,271.849579 299.759033,277.397827 
z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
